.login-card
{
    margin-top: 20px;
}

.login-card {
    img {
        margin-bottom: 40px;
        width: 40%;
    }

	.form-control {
	  font-size: 16px;
	  padding: 16px;
	  height: auto;
	  box-shadow: none;
	  color: $primaryTextColor;
	}

    .center-block {
        text-align: center;

        a {
            text-decoration: none;
        }
    }
}

@media (max-width: $mobileBreakpoint) {
    .login-card img {
        margin-top: 10px;
        width: 60%;
    }
}

@media (min-width: $mobileBreakpoint) {
    .login-card
    {
        margin-top: 60px;
    }
}
