@font-face {
  font-family: 'ageras-punch-font';
  src:  url('fonts/ageras-punch-font.eot?9160rj');
  src:  url('fonts/ageras-punch-font.eot?9160rj#iefix') format('embedded-opentype'),
    url('fonts/ageras-punch-font.ttf?9160rj') format('truetype'),
    url('fonts/ageras-punch-font.woff?9160rj') format('woff'),
    url('fonts/ageras-punch-font.svg?9160rj#ageras-punch-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ap-"], [class*=" ap-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ageras-punch-font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ap-phone:before {
  content: "\e913";
}
.ap-attachment:before {
  content: "\e910";
}
.ap-add:before {
  content: "\e911";
}
.ap-remove:before {
  content: "\e912";
}
.ap-bell:before {
  content: "\e909";
}
.ap-exclamation:before {
  content: "\e908";
}
.ap-filter:before {
  content: "\e907";
}
.ap-burger:before {
  content: "\e905";
}
.ap-star:before {
  content: "\e903";
}
.ap-arrow-down:before {
  content: "\e902";
}
.ap-punch:before {
  content: "\e900";
}
.ap-task:before {
  content: "\e901";
}
.ap-cross:before {
  content: "\ea0f";
}
.ap-checkmark:before {
  content: "\ea10";
}
.ap-file-pdf:before {
  content: "\eadf";
}
.ap-user:before {
  content: "\e971";
}
.ap-credit-card:before {
  content: "\e90e";
}
.ap-house:before {
  content: "\e90f";
}
.ap-certifications:before {
  content: "\e90c";
}
.ap-pin:before {
  content: "\e90d";
}
.ap-file:before {
  content: "\e90b";
}
.ap-refill:before {
  content: "\e90a";
}
.ap-magnify:before {
  content: "\e906";
}
.ap-lock-open:before {
  content: "\e904";
}

.ap-arrow-right:before {
  -webkit-transform: rotate(-90deg); /* Safari and Chrome */
  -moz-transform: rotate(-90deg);   /* Firefox */
  -ms-transform: rotate(-90deg);   /* IE 9 */
  -o-transform: rotate(-90deg);   /* Opera */
  transform: rotate(-90deg);
  display: inline-block;
  content: "\e902";
}
