@keyframes gradient {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.btn {
    transition: background 200ms ease;
    color: #fff;
    border: none;
    border-radius: 3px;
    text-decoration: none !important; //oops
    white-space: initial;

    &:hover, &:active, &:focus {
        color: #fff;
    }

    // sizes
    &.btn-lg {
        padding: 20px;
    }


    // colors
    &.btn-primary {
        background-color: $primaryColor;

        &:hover {
            background-color: lighten($primaryColor, 3%);
        }
        &:active, &:focus {
            background-color: darken($primaryColor, 3%);
            box-shadow: none;
        }
        .icon {
            color: $primaryColor;
            background-color: #fff;
        }
    }

    &.btn-secondary {
        background-color: $secondaryColor;

        &:hover {
            background-color: lighten($secondaryColor, 3%);
        }
        &:active, &:focus {
            background-color: darken($secondaryColor, 3%);
            box-shadow: none;
        }
    }

    &.btn-secondary-two {
        background-color: $secondaryColorTwo;

        &:hover {
            background-color: lighten($secondaryColorTwo, 3%);
        }
        &:active, &:focus {
            background-color: darken($secondaryColorTwo, 3%);
            box-shadow: none;
        }
    }

    &.btn-action {
        background-color: $actionColor;

        &:hover {
            background-color: lighten($actionColor, 3%);
        }
        &:active, &:focus {
            background-color: darken($actionColor, 3%);
            box-shadow: none;
        }
        .icon {
            color: $actionColor;
            background-color: #fff;
        }
    }

    &.btn-shimmer {
        background: linear-gradient(-45deg, #25CA74, #0E9E54, #34EA8C, #2F9460);
        background-size: 400% 400%;
        animation: gradient 5s ease infinite;
    }

    &.btn-warning {
        background-color: $warningColor;

        &:hover {
            background-color: lighten($warningColor, 3%);
        }
        &:active, &:focus {
            background-color: darken($warningColor, 3%);
            box-shadow: none;
        }
        .icon {
            color: $warningColor;
            background-color: #fff;
        }
    }

    &.btn-danger {
        background-color: $dangerColor;

        &:hover {
            background-color: lighten($dangerColor, 3%);
        }
        &:active, &:focus {
            background-color: darken($dangerColor, 3%);
            box-shadow: none;
        }
        .icon {
            color: $dangerColor;
            background-color: #fff;
        }
    }

    &.btn-remove {
        background-color: $removeColor;
        &:hover {
            background-color: lighten($removeColor, 3%);
        }
        &:active, &:focus {
            background-color: darken($removeColor, 3%);
            box-shadow: none;
        }
        .icon {
            color: $removeColor;
            background-color: #fff;
        }
    }

    &.btn-with-icon{
        height: 30px;
        width: 30px;
        line-height: 32px;
        padding: 0;
        font-size: 10px;
        margin: auto;
    }

    &.btn-card {
        @extend .card;

        display: block;
        color: #000;
        text-align: left;
        text-decoration: none;
        padding: 25px;
        margin-bottom: 15px;

        .btn {
            margin-top: -3px;
        }
    }

    &.btn-tab {
        display: inline-block;
        color: $secondaryColorTwo;
        border: 1px solid $cardBorderColor;
        border-radius: 3px;
        padding: 15px;
        margin-right: 30px;
        width: 180px;
        font-size: $baseFontSize;

        &:hover {
            background: lighten($cardBorderColor, 15%);
        }

        &.active {
            //@extend .btn-primary;
            border: 2px solid $primaryColor;
            color: $primaryTextColor;
            box-shadow: none;
        }

    }
    .icon {
        display: inline-block;
        float: left;
        margin: -9px;
        font-size: 35px;
        line-height: 35px;
        border-radius: 3px;
        text-decoration: none;
    }

    .icon-padder {
        padding-left: 25px;
    }

    &.btn-success {
        background-color: red;
    }

    &.btn-lg.has-icon {
        padding: 0px;
        margin-right: 10px;
        .ap-attachment {
            font-size: 35px;
            padding: 15px 20px;

            @media (max-width: $phoneBreakpoint) {
                font-size: 30px;
                line-height: 42px;
                padding: 14px;
            }
        }
    }
}

@media (max-width: $phoneBreakpoint) {
    .btn {
        font-size: 16px;
        line-height: 22px;
        overflow: visible;

        &.btn-lg {
            padding: 10px 20px;
        }
    }
}
.btn-toolbar {
    * {
        outline: none;
    }
}
