#demo-page {
    background: #fff;
    margin-top: -19px;
    color: $landingPageBodyText;
    h3 {
        color: $landingPageBodyText;
    }

    .steps-container {

    }

    .offers-container {
        margin: 50px 0 50px 0;
    }

    .demo-video {
        @extend .card;
        padding: 0;
        margin-top: 20px;

        video {
            display: block;
            object-fit: cover;
        }
    }

    .partners-section {
        @extend .card;
        text-align: center;
        font-size: 28px;
        font-weight: 300;
        display: none;
    }

}