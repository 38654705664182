#pro-upgrade-page {
    background: #fff;
    margin-top: -19px;
    color: $landingPageBodyText;

    .demo-video {
        @extend .card;
        padding: 0;
        margin-top: 20px;

        video {
            display: block;
            object-fit: cover;
        }
    }
}