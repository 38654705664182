.mini-landing {
    text-align: center;

    h1 {
        color: $activeColor;
        font-weight: 300;
        font-size: 38px;
        line-height: 48px;
    }
    p {
        font-size: 22px;
    }
    .icon {
        margin: 50px auto 20px auto;
        background: none;
        width: 100px;
        height: 100px;
    }
    .btn {
        margin: 30px;
    }
}

@media (min-width: $mobileBreakpoint) {
    .mini-landing {
        padding: 0 120px;
    }
}