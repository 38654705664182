.signup {
  &__page {
    width: 100%;
    min-height: 100%;
    position: absolute;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__card {
    box-shadow: none;
    border: none;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px;
      position: relative;

      > span {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #353535;
      }
    }

    *:before, *:after {
      box-sizing: unset;
    }

    label {
      font-weight: normal;
    }

    .phone-input {
      img {
        max-width: unset;
      }
    }


    @media all and (min-width: 1100px) {
      max-width: 600px;
    }

    .center-block {
      text-align: center;

      a {
        text-decoration: none;
      }
    }
  }
}
