// IMPORTANT: DO NOT USE THE FOLLOWING VARIABLES OUTSIDE THIS FILE
// Non semantic variable names may only be used internally in this file, so semantic named variables can reference them
// For example:
// $red = #d60000;
// $red can only be referenced inside this file, by a semantically named variable
$darkBlue: #333C45;
$lightBlue: #00A4EB;

$lightGrey: #E1E7E9;
$evenLighterGrey: #EEE;
$mediumGrey: #7E7E7E;
$darkGrey: #333;

// SEMANTIC VARIABLES: May be used outside this file
// Use semantic names eg. $backgroundColor, $primaryTextColor, $primaryBorderColor etc. - Non semantic variables may be referenced by these.

$primaryTextColor: #505050;
$backgroundColor: #F9FAFC; // #E6E8EB;
$primaryFontFamily:  'Mont', 'Segoe UI', Helvetica, Arial, sans-serif;
$fallbackFontFamily: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;

$activeColor: $lightBlue;
$ctaColor: #25CA74; //
$primaryColor: $lightBlue;
$secondaryColor: #ccc;
$secondaryColorTwo: #9DAEB6;
$actionColor: $ctaColor; // alias
$dangerColor: #e63637; //ee5152;
$fadedDangerColor: rgba(230, 54, 55, 0.81);
$warningColor: #f0861c;
$successColor: $ctaColor;
$fadedSuccessColor: rgba(37, 202, 116, 0.81);
$dialogColor: #A7B7BE;
$notificationColor: #FC604C;
$removeColor: $notificationColor;

$cardBorderColor: #C7D7DD;
$cardHeaderBackgroundColor: #f3f3f3;
$cardMarginTop: 0;
$cardMarginBottom: 30px;

$baseFontSize: 16px;
$baseFontSizeMobile: 15px;
$baseHeaderSize: 22px;

// text color
$fadedTextColor: #7E7E7E;
$fadedTextColorTwo: #B3B3B3;
$introTextColor: $mediumGrey;
$textIconColor: $fadedTextColorTwo;
$breadcrumbIconColor: #929b9e;
$landingPageBodyText: #65768C;
$landingSubHeaderTextColor: $darkBlue;
$landingHeaderTextColor: $lightBlue;

$textSelectionBackgroundColor: $evenLighterGrey;

$headerTopBackground: $darkBlue;
$footerBackground: $darkBlue;
$headerBottomBackground: #fff;
$headerBottomColor: $darkBlue;

$wellBackgroundColor: #F2F6F8;

$mobileMenuBorderColor: $lightGrey;

$sidebarBorderColor: $lightGrey;


$captionColor: $mediumGrey;


$cardBorderColor: #C7D7DD;

$senderMessage: $lightBlue;
$partnerMessage: $backgroundColor;
$noteInfoText: $fadedTextColorTwo;

// forms
$formInputBorderColor: $lightGrey;
$signupErrorBackgroundColor: #fffafa;
$signupErrorTextColor: $dangerColor;

// breakpoints
$mobileBreakpoint: 855px;
$punchesDropdownBreakpoint: 820px;
$userDropdownBreakpoint: 550px;
$phoneBreakpoint: 550px;

// bootstrap breakpoint
$xl-breakpoint: 1140px;
$sm-breakpoint: 540px;

// satisfaction
$satisfaction-very-happy: #00AF5B;
$satisfaction-mostly-happy: #7ED321;
$satisfaction-somewhat-unhappy: #F5A623;
$satisfaction-very-unhappy: #EE5152;

// prompts
$promptDismissColor: lighten($mediumGrey, 20%);

// global warnings
$warningBackgroundColor: #FFD48E;
$dangerBackgroundColor: $satisfaction-very-unhappy;
