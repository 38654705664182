
@media (min-width: $mobileBreakpoint) {
    .mobile-hidden-up {
        display: none;
    }
}
@media (max-width: $mobileBreakpoint) {
    .mobile-hidden-down {
        display: none;
    }
    .container-fluid.reset-mobile-fluid-padding {
        padding: 0;
    }

    .card {
        padding: 20px;

        &.has-header {
            .header, .content {
                padding: 20px;
            }
        }
    }

    .nav.nav-pills {
        margin-top: $cardMarginTop;

        li {
            float: none;
            &:last-child {
                a {
                    margin-bottom: 0;
                }
            }

            a {
            padding: 15px;
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 16px;
            width: 100%;
            }
        }
    }

    .sidebar-nav {
        .secondary {
            display: none;
        }
    }



// Center logo when user dropdowns disappear

    #page-header {
            .logo .pro {
                    display: none !important;
                }

        .pull-right .button {
                line-height: 40px;
                color: #fff;
                margin-left: 15px;
            }
    }

// Card specifics for mobile

    .card {
        margin-bottom: 20px;
    }
    .col-sm-6, .col-sm-4{
        margin-bottom: 0px;
    }

    .breadcrumb {
        padding: 8px;
        .step {
            margin: 3px;
        }
        .action-checkbox {
            margin: 0 3px;
        }
        .ap-arrow-right {
            display: none;
        }
        .ap-checkmark {
            padding: 3px;
        }
        .step-text {
            font-size: 14px;
            margin: 0px 5px 0px 3px;
        }
    }

    #dixa-widget-wrapper {
        display: none;
    }
}
