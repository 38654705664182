@import "../base/variables";

#addUser {
  .user {
	display: inline-block;
	border-bottom: 1px solid $lightGrey;
	margin: 20px auto;
	padding-bottom: 31px;
	p {
	  margin: 0;
	  padding: 0;
	}
	a {
	  cursor: pointer;
	}
  }

  .checkbox-rounded {
	background: #fcfff4;
	border-radius: 50px;
	input[type=checkbox] {
	  float: left;
	  width: 28px;
	  padding: 19px 10px;
	}
	label {
	  margin: 14px 16px;
	}
  }
}