.slick-dots {
  position: initial;
  li {
    height: 10px;
    width: 10px;
    button:before {
      font-size: 12px;
    }
    &.slick-active, &:hover {
      button:before {
        color: $activeColor;
      }
    }
  }
}

.score-counter {
  background-color: $actionColor;
  border-radius: 25px;
  display: inline-block;
  color: #fff;
  padding: 5px 15px;
  margin-right: 20px;
}
