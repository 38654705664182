.card.lead-card {
    p.description {
        margin: 20px 0;
        line-height: 26px;
    }

    div.quote {
        margin-bottom: 30px;

        p {
            margin: 5px 0;
            padding: 0;
            display: block;
        }
    }

    a {
        text-decoration: none;
    }

    h4 {
        padding-left: 60px;
        min-height: 56px;

        .icon {
            margin-top: 5px;
            margin-left: -60px;
            float: left;
        }
    }
}

// Mobile

@media (max-width: $userDropdownBreakpoint) {
    .card.lead-card {
        p.description {
            margin: 20px 0;
            line-height: 23px;
        }

        h4 {
            padding-left: 0;
            min-height: 56px;

            .icon {
                display: none;
                margin-top: 5px;
                margin-left: -55px;
                float: left;
            }
        }
    }
}

.lead-unavailable {
    opacity: 0.5;
}
