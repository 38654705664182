.account-billing {
  .media-left {
    background-color: $backgroundColor;
    borderRight: 1px solid $cardBorderColor;
  }

  .media-body svg {
    float: right;
    color: #ccc;
  }
}
