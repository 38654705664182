#cv-page {

  .list-item {
    width: 100%;
    padding: 10px;
  }

  .year-hyphen {
    position: absolute;
    top: 0;
    right: -4px;
    line-height: 48px;
    color: $fadedTextColor;

    @media (max-width: 991px) {
      display: none;
    }
  }

  .form-group {
    select {
      height: 47px;
    }
  }

}
