.alert {
    border-radius: 3px;

    &.alert-active {
        background-color: $activeColor;
        color: #fff;
    }
}

.label {
    &.label-success {
        background-color: $actionColor;
    }
}

.timeout-alert {
    width: 50%;
    margin: 50px auto;
}

.well {
    background-color: $wellBackgroundColor;
    padding: 15px;
    margin-bottom: 30px;
}

.global-alerts {
    .global-alert {
        text-align: center;
        padding: 8px;
        font-size: 14px;

        &.global-alert-warning {
            background-color: $warningBackgroundColor;
            border-top: 1px solid lighten($warningBackgroundColor, 10%);
            border-bottom: 1px solid lighten($warningBackgroundColor, 10%);
        }
    }
}
