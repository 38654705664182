// In this file we define typography
// p, headings etc. can not have colors defined directly, but must be “inherit”
// Primary text color is defined on body so they will be inherited

// Desktop + tablet

body {
    background-color: $backgroundColor;
    color: $primaryTextColor;
    font-size: $baseFontSize;
    font-family: $fallbackFontFamily;
}

body .new {
    font-family: $primaryFontFamily;
}

h1, h2, h3, h4, h5 {
    margin-top: 0;
    color: $darkBlue;
}

h2 {
    font-size: 22px;
    font-weight: normal;
}

h3 {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 5px;
    font-weight: normal;
}

h4 {
    font-size: 20px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 10px;
    color: $darkBlue;
}

h5 {
    font-size: 16px;
    line-height: 120%;
    margin: 3px 0 10px 0;
}

a {
    color: inherit;
    text-decoration: none;
}

p.intro-text {
    color: $introTextColor;
    margin: 5px 0 30px 0;
}

.intro-text-color, span.meta {
    color: $introTextColor;
}

.legal-disclaimer {
    font-size: 80%;
    color: $fadedTextColor;
    margin-bottom: 10px;
}

p.caption {
    text-align: center;
    font-size: 14px;
    margin-top: 2px;
    color: $captionColor;
}

.text-active {
    color: $activeColor;
    &_link {
        text-decoration: underline;
        color: $activeColor;
    }
}
.action-color {
    color: $actionColor
}
code {
    display: block;
    font-size: 14px;
    background-color: #eee;
    margin-top: 10px;
    padding: 10px;
    border-radius: 3px;
}

span.label {
    &.label-subscription {
        background-color: $activeColor;
    }
}

.left-attention
{
    border-left: 4px solid $dialogColor;
    padding-left: 20px;

    p {
        margin:4px 0;
    }
}

blockquote {
    @extend .left-attention;

    margin: 15px 0 0 0;
}

.caption {
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: $introTextColor;
    display: block;
}

// Meta lists
ul.meta {
    margin: 0;
    padding: 0;

    li {
        padding: 0;
        margin: 0;
        color: $fadedTextColor;
        display: inline;
        padding-right: 5px;
        font-size: 10pt;

        &:not(:last-of-type):after {
            content:'\00B7';
            padding-left: 5px;
        }
    }
}

// Mobile

@media (max-width: $mobileBreakpoint) {
    body {
        font-size: $baseFontSizeMobile;
    }
}

@media (max-width: $userDropdownBreakpoint) {
    h4 {
        font-size: 18px;
    }
}
.text-bold {
    font-weight: bold;
}
.text-center {
    text-align: center;
}

.center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}

.grid-content {
    display: grid;
}

div.amount {
    &.amount-lg {
        span.amount {
            font-size: 32px;
        }
    }

    span.amount {
        color: $activeColor;
        &:after {
            content: " ";
        }
    }
    span.currency {
        color: $fadedTextColor;
    }
}

.text-gradient-fade {
    max-height: 260px;
    overflow: hidden;
    position: relative;

    &:after {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.67+38,1+96 */
        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.67) 38%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.67) 38%,rgba(255,255,255,1) 96%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.67) 38%,rgba(255,255,255,1) 96%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 160px;
        margin-bottom: -1px;
    }
}

a.readmore-link {
    //color: $primaryColor;
    display: block;
    font-weight: medium;
    margin-top: 10px;
    text-decoration: underline;
    cursor: pointer;
}

.sticky-top {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
}

.fixed-sticky {
    vertical-align: top;
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
}


.dot-underline-text {
    &[data-tip] {
        cursor: pointer;
    }
    display: inline-block;
    border-bottom: 1px dashed ;
}

.brake-words {
    word-wrap: break-word;
}

.label-new {
    background-color: #9DAEB6;
}

.cursor-pointer {
    cursor: pointer;
}
