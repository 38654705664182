.quote-price-review {
    td.text-right {
        @media (max-width: $mobileBreakpoint) {
            text-align: left;
        }
    }
}

.message-form {
    .textarea-wrapper {
        border: 1px solid $cardBorderColor;
        padding: 10px;
        border-radius: 3px;
        textarea {
            border: 0;
            resize: none;
            outline: none;
            margin: 0;
            font-size: 16px;
            min-height: 64px;
            padding: 0;
        }
    }
}


.quote-edit-form {
    h2 {
        margin: 0;
    }

    &.disabled {
        opacity: 0.4;
    }
    .datatable {
        td, th {
            height: 0;
            padding: 15px;
        }
    }
    @media (max-width: $phoneBreakpoint) {
        th {
            word-break: break-all;
        }
        col {
            width: 30%;
        }
    }
}

.react-markdown {
    h1 {
        font-size: 18px;
        color: $landingPageBodyText
    }
}

.nav-tabs.quote-tabs {
    a {
        margin-right: 0;
        background-color: #F9FBFC;
        color: #9AAEB6;
        border: 1px solid #ddd;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        .company-name {
            font-weight: bold;
        }
        &:hover {
            border: 1px solid #ddd;
        }
    }
    li {
        width: 200px;
        &:not(:last-child) a {
            border-right: none;
        }
        &.active a {
            background-color: #fff;
            color: #333c45
        }

    }

    @media (max-width: $mobileBreakpoint) {
        li {
            width: 33%;
            font-size: 12px;
            a {
                padding: 7px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: pre-line;
                max-height: 45px;
                line-height: 13px;
            }
        }
        small {
            display: none;
        }
    }
}

.heading-section {
    margin: 0 auto 50px auto;
    max-width: 750px;
    text-align: center;


    .heading {
        &_large {
            font-size: 46px;
            font-weight: 500;
            color: $activeColor;
        }

        &_sub {
            font-size: 24px;
            color: $fadedTextColor;
        }
    }
}
