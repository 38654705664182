.subscription-info {
  .blocking-modal .body {
    width: 760px
  }
  .confetti {
    padding-top: 30px;
    .page-splash-wrapper {
      width: 100%;
      height: 100%;
      background-size: 100% 100%;
      /*
      background-image: url(/images/confetti.svg);
       */
      background-repeat: no-repeat;
    }
    .intro-heading {
      font-size: 46px;
    }
    .page-splash {
      max-width: 750px;
      margin: auto;
      .sub-header {
        font-size: 24px;
        color: $fadedTextColor;
      }
      .intro-text-color {
        margin: 20px;
      }
      .warning-card {
        display: flex;
        align-items: center;
        .text-center{
          margin: 0 20px;
        }
      }
    }
    .subscription-preview {
      margin: auto;
      max-width: 950px;
      .row {
        margin-top: 60px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
          display: block;
          margin: 20px;
        }
        strong {
          color: $activeColor;
          font-size: 20px;
          @media (max-width: 600px) {
            padding-top: 20px;
          }
        }
        p {
          color: $fadedTextColor;
          margin-bottom: 0;
        }
      }
    }
    .step-box .step-description {
      color: $fadedTextColor
    }
  }
}
