.react-date-field {
  box-sizing: border-box;
  position: relative;
  overflow: visible;
  vertical-align: middle;
  * {
	box-sizing: border-box;
	&:before, &:after {
	  box-sizing: border-box;
	}
  }
  > .react-date-field__picker {
	min-width: 100%;
	position: absolute;
	z-index: 100;
	left: -1px;
	background: white;
  }
}

.react-date-field--picker-position-bottom > .react-date-field__picker {
  top: 100%;
}

.react-date-field--picker-position-top > .react-date-field__picker {
  bottom: 100%;
}

.react-date-field__input {
  width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 5px;
  border: none;
  outline: none;
}

.react-date-field__calendar-icon {
  border: 3px solid gray;
  border-top-width: 5px;
  border-radius: 2px;
  padding: 5px 7px;
  margin: 2px 4px 0px 2px;
  position: relative;
}

.react-date-field__clear-icon {
  color: gray;
  fill: gray;
  margin: 0px 2px;
  cursor: pointer;
  svg {
	vertical-align: middle;
  }
  &:hover {
	color: #4d4d4d;
	fill: #4d4d4d;
  }
}

.react-date-field__calendar-icon {
  &:after, &:before {
	content: '';
	width: 3px;
	height: 6px;
	border-radius: 10px;
	left: 1px;
	top: -6px;
	position: absolute;
	background: gray;
  }
  &:after {
	left: auto;
	right: 0px;
  }
}

.react-date-field__calendar-icon-inner {
  background: gray;
  position: absolute;
  border-radius: 1px;
  top: 2px;
  left: 2px;
  width: 4px;
  height: 4px;
}

.react-date-picker__clock {
  box-sizing: border-box;
  display: inline-block;
  border: 5px solid gray;
  border-radius: 50%;
  position: relative;
  * {
	box-sizing: border-box;
	&:before, &:after {
	  box-sizing: border-box;
	}
  }
}

.react-date-picker__clock-overlay, .react-date-picker__clock-center {
  border-radius: 50%;
  position: absolute;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
}

.react-date-picker__clock-hand, .react-date-picker__clock-tick {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  background: gray;
}

.react-date-picker__date-format-spinner {
  box-sizing: border-box;
  * {
	box-sizing: border-box;
	&:before, &:after {
	  box-sizing: border-box;
	}
  }
}

.react-date-picker__time-picker {
  box-sizing: border-box;
  * {
	box-sizing: border-box;
	&:before, &:after {
	  box-sizing: border-box;
	}
  }
}

.react-date-picker__time-picker-input {
  margin-top: 10px;
}

.react-date-picker__year-view {
  box-sizing: border-box;
  outline: none;
  * {
	box-sizing: border-box;
	&:before, &:after {
	  box-sizing: border-box;
	}
  }
}

.react-date-picker__year-view-month {
  text-align: center;
}

.react-date-picker__decade-view {
  box-sizing: border-box;
  outline: none;
  * {
	box-sizing: border-box;
	&:before, &:after {
	  box-sizing: border-box;
	}
  }
}

.react-date-picker__decade-view-year {
  text-align: center;
}

.react-date-picker__history-view {
  box-sizing: border-box;
  outline: none;
  * {
	box-sizing: border-box;
	&:before, &:after {
	  box-sizing: border-box;
	}
  }
}

.react-date-picker__nav-bar {
  box-sizing: border-box;
  outline: none;
  position: relative;
  * {
	box-sizing: border-box;
	&:before, &:after {
	  box-sizing: border-box;
	}
  }
}

.react-date-picker__nav-bar-arrow {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  position: relative;
}

.react-date-picker__nav-bar-arrow--disabled {
  fill: #BFBFBF;
  cursor: default;
}

.react-date-picker__nav-bar-date {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-date-picker__nav-bar svg {
  vertical-align: middle;
}

.react-date-picker__nav-bar-history-view {
  z-index: 100;
  position: absolute;
  margin: auto;
  left: 3px;
  right: 3px;
  top: 100%;
}

.react-date-picker, .react-date-picker__calendar, .react-date-picker__basic-month-view {
  box-sizing: border-box;
}

.react-date-picker * {
  box-sizing: border-box;
  &:before, &:after {
	box-sizing: border-box;
  }
}

.react-date-picker__calendar * {
  box-sizing: border-box;
  &:before, &:after {
	box-sizing: border-box;
  }
}

.react-date-picker__basic-month-view * {
  box-sizing: border-box;
  &:before, &:after {
	box-sizing: border-box;
  }
}

.react-date-picker__transition-month-view {
  position: relative;
  overflow: hidden;
}

.react-date-picker__prev {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
}

.react-date-picker__next {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
}

.react-date-picker--transition-left {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
  &.react-date-picker__next {
	-webkit-transform: translate3d(0%, 0px, 0px);
	transform: translate3d(0%, 0px, 0px);
  }
}

.react-date-picker--transition-right {
  z-index: 1111;
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  &.react-date-picker__prev {
	-webkit-transform: translate3d(0%, 0px, 0px);
	transform: translate3d(0%, 0px, 0px);
  }
}

.react-date-picker__center {
  z-index: 10;
}

.react-date-picker__prev.react-date-picker--transition, .react-date-picker__center.react-date-picker--transition, .react-date-picker__next.react-date-picker--transition {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.react-date-picker__prev, .react-date-picker__next {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  position: absolute !important;
}

.react-date-picker__month-view, .react-date-picker__basic-month-view {
  position: relative;
  outline: none;
}

.react-date-picker__month-view-week-day-name, .react-date-picker__basic-month-view-week-day-name {
  padding: 5px 0px;
}

.react-date-picker__month-view-day--hidden, .react-date-picker__basic-month-view-day--hidden {
  visibility: hidden;
}

.react-date-picker__month-view-day--disabled, .react-date-picker__basic-month-view-day--disabled {
  color: #BFBFBF;
}

.react-date-picker__month-view-cell, .react-date-picker__basic-month-view-cell {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.react-date-picker__month-view-row, .react-date-picker__basic-month-view-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.react-date-picker__month-view-week-day-names, .react-date-picker__basic-month-view-week-day-names {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.react-flex-v2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.react-flex-v2--inline {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.react-flex-v2--display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.react-flex-v2--display-inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

/* ALIGN-ITEMS */

.react-flex-v2--align-items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.react-flex-v2--align-items-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  -ms-grid-row-align: stretch;
  align-items: stretch;
}

.react-flex-v2--align-items-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  -ms-grid-row-align: baseline;
  align-items: baseline;
}

.react-flex-v2--align-items-end, .react-flex-v2--align-items-flex-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
}

.react-flex-v2--align-items-start, .react-flex-v2--align-items-flex-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
}

/* ALIGN-SELF */

.react-flex-v2--align-self-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.react-flex-v2--align-self-stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.react-flex-v2--align-self-baseline {
  -webkit-align-self: baseline;
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.react-flex-v2--align-self-auto {
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.react-flex-v2--align-self-end, .react-flex-v2--align-self-flex-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.react-flex-v2--align-self-start, .react-flex-v2--align-self-flex-start {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

/* ALIGN-CONTENT */

.react-flex-v2--align-content-center {
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.react-flex-v2--align-content-stretch {
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.react-flex-v2--align-content-around, .react-flex-v2--align-content-space-around {
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.react-flex-v2--align-content-between, .react-flex-v2--align-content-space-between {
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.react-flex-v2--align-content-end, .react-flex-v2--align-content-flex-end {
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.react-flex-v2--align-content-start, .react-flex-v2--align-content-flex-start {
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

/* JUSTIFY-CONTENT */

.react-flex-v2--justify-content-start, .react-flex-v2--justify-content-flex-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.react-flex-v2--justify-content-end, .react-flex-v2--justify-content-flex-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.react-flex-v2--justify-content-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.react-flex-v2--justify-content-space-around {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.react-flex-v2--justify-content-space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* WRAP */

.react-flex-v2--wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* COLUMN */

.react-flex-v2--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.react-flex-v2--column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

/* ROW */

.react-flex-v2--row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.react-flex-v2--row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

/* FLEX-BASIS */

.react-flex-v2--flex-basis-auto {
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.react-flex-v2--flex-basis-none, .react-flex-v2--flex-basis-0 {
  -webkit-flex-basis: 0px;
  -ms-flex-preferred-size: 0px;
  flex-basis: 0px;
}

.react-flex-v2--flex-basis-fill {
  -webkit-flex-basis: fill;
  -ms-flex-preferred-size: fill;
  flex-basis: fill;
}

.react-flex-v2--flex-basis-content {
  -webkit-flex-basis: content;
  -ms-flex-preferred-size: content;
  flex-basis: content;
}

.react-flex-v2--flex-basis-fit-content {
  -webkit-flex-basis: fit-content;
  -ms-flex-preferred-size: fit-content;
  flex-basis: fit-content;
}

.react-flex-v2--flex-basis-min-content {
  -webkit-flex-basis: min-content;
  -ms-flex-preferred-size: min-content;
  flex-basis: min-content;
}

.react-flex-v2--flex-basis-max-content {
  -webkit-flex-basis: max-content;
  -ms-flex-preferred-size: max-content;
  flex-basis: max-content;
}

/* FLEX */

.react-flex-v2--flex-none, .react-flex-v2--flex-0 {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.react-flex-v2--flex-1 {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.react-flex-v2--flex-2 {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

.react-flex-v2--flex-3 {
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

.react-flex-v2--flex-4 {
  -webkit-box-flex: 4;
  -webkit-flex: 4;
  -ms-flex: 4;
  flex: 4;
}

.react-flex-v2--flex-5 {
  -webkit-box-flex: 5;
  -webkit-flex: 5;
  -ms-flex: 5;
  flex: 5;
}

.react-flex-v2--flex-6 {
  -webkit-box-flex: 6;
  -webkit-flex: 6;
  -ms-flex: 6;
  flex: 6;
}

.react-flex-v2--flex-7 {
  -webkit-box-flex: 7;
  -webkit-flex: 7;
  -ms-flex: 7;
  flex: 7;
}

.react-flex-v2--flex-8 {
  -webkit-box-flex: 8;
  -webkit-flex: 8;
  -ms-flex: 8;
  flex: 8;
}

.react-flex-v2--flex-9 {
  -webkit-box-flex: 9;
  -webkit-flex: 9;
  -ms-flex: 9;
  flex: 9;
}

.react-flex-v2--flex-10 {
  -webkit-box-flex: 10;
  -webkit-flex: 10;
  -ms-flex: 10;
  flex: 10;
}

.react-flex-v2--flex-11 {
  -webkit-box-flex: 11;
  -webkit-flex: 11;
  -ms-flex: 11;
  flex: 11;
}

.react-flex-v2--flex-12 {
  -webkit-box-flex: 12;
  -webkit-flex: 12;
  -ms-flex: 12;
  flex: 12;
}

.react-flex-v2--flex-13 {
  -webkit-box-flex: 13;
  -webkit-flex: 13;
  -ms-flex: 13;
  flex: 13;
}

.react-flex-v2--flex-14 {
  -webkit-box-flex: 14;
  -webkit-flex: 14;
  -ms-flex: 14;
  flex: 14;
}

.react-flex-v2--flex-15 {
  -webkit-box-flex: 15;
  -webkit-flex: 15;
  -ms-flex: 15;
  flex: 15;
}

.react-flex-v2--flex-16 {
  -webkit-box-flex: 16;
  -webkit-flex: 16;
  -ms-flex: 16;
  flex: 16;
}

.react-flex-v2--flex-17 {
  -webkit-box-flex: 17;
  -webkit-flex: 17;
  -ms-flex: 17;
  flex: 17;
}

.react-flex-v2--flex-18 {
  -webkit-box-flex: 18;
  -webkit-flex: 18;
  -ms-flex: 18;
  flex: 18;
}

.react-flex-v2--flex-19 {
  -webkit-box-flex: 19;
  -webkit-flex: 19;
  -ms-flex: 19;
  flex: 19;
}

.react-flex-v2--flex-20 {
  -webkit-box-flex: 20;
  -webkit-flex: 20;
  -ms-flex: 20;
  flex: 20;
}

.react-flex-v2--flex-21 {
  -webkit-box-flex: 21;
  -webkit-flex: 21;
  -ms-flex: 21;
  flex: 21;
}

.react-flex-v2--flex-22 {
  -webkit-box-flex: 22;
  -webkit-flex: 22;
  -ms-flex: 22;
  flex: 22;
}

.react-flex-v2--flex-23 {
  -webkit-box-flex: 23;
  -webkit-flex: 23;
  -ms-flex: 23;
  flex: 23;
}

.react-flex-v2--flex-24 {
  -webkit-box-flex: 24;
  -webkit-flex: 24;
  -ms-flex: 24;
  flex: 24;
}

.react-flex-v2--flex-25 {
  -webkit-box-flex: 25;
  -webkit-flex: 25;
  -ms-flex: 25;
  flex: 25;
}

.react-flex-v2--flex-26 {
  -webkit-box-flex: 26;
  -webkit-flex: 26;
  -ms-flex: 26;
  flex: 26;
}

.react-flex-v2--flex-27 {
  -webkit-box-flex: 27;
  -webkit-flex: 27;
  -ms-flex: 27;
  flex: 27;
}

.react-flex-v2--flex-28 {
  -webkit-box-flex: 28;
  -webkit-flex: 28;
  -ms-flex: 28;
  flex: 28;
}

.react-flex-v2--flex-29 {
  -webkit-box-flex: 29;
  -webkit-flex: 29;
  -ms-flex: 29;
  flex: 29;
}

.react-flex-v2--flex-30 {
  -webkit-box-flex: 30;
  -webkit-flex: 30;
  -ms-flex: 30;
  flex: 30;
}

.react-flex-v2--flex-31 {
  -webkit-box-flex: 31;
  -webkit-flex: 31;
  -ms-flex: 31;
  flex: 31;
}

.react-flex-v2--flex-32 {
  -webkit-box-flex: 32;
  -webkit-flex: 32;
  -ms-flex: 32;
  flex: 32;
}

.react-flex-v2--flex-33 {
  -webkit-box-flex: 33;
  -webkit-flex: 33;
  -ms-flex: 33;
  flex: 33;
}

.react-flex-v2--flex-34 {
  -webkit-box-flex: 34;
  -webkit-flex: 34;
  -ms-flex: 34;
  flex: 34;
}

.react-flex-v2--flex-35 {
  -webkit-box-flex: 35;
  -webkit-flex: 35;
  -ms-flex: 35;
  flex: 35;
}

.react-flex-v2--flex-36 {
  -webkit-box-flex: 36;
  -webkit-flex: 36;
  -ms-flex: 36;
  flex: 36;
}

.react-flex-v2--flex-37 {
  -webkit-box-flex: 37;
  -webkit-flex: 37;
  -ms-flex: 37;
  flex: 37;
}

.react-flex-v2--flex-38 {
  -webkit-box-flex: 38;
  -webkit-flex: 38;
  -ms-flex: 38;
  flex: 38;
}

.react-flex-v2--flex-39 {
  -webkit-box-flex: 39;
  -webkit-flex: 39;
  -ms-flex: 39;
  flex: 39;
}

.react-flex-v2--flex-40 {
  -webkit-box-flex: 40;
  -webkit-flex: 40;
  -ms-flex: 40;
  flex: 40;
}

.react-flex-v2--flex-41 {
  -webkit-box-flex: 41;
  -webkit-flex: 41;
  -ms-flex: 41;
  flex: 41;
}

.react-flex-v2--flex-42 {
  -webkit-box-flex: 42;
  -webkit-flex: 42;
  -ms-flex: 42;
  flex: 42;
}

.react-flex-v2--flex-43 {
  -webkit-box-flex: 43;
  -webkit-flex: 43;
  -ms-flex: 43;
  flex: 43;
}

.react-flex-v2--flex-44 {
  -webkit-box-flex: 44;
  -webkit-flex: 44;
  -ms-flex: 44;
  flex: 44;
}

.react-flex-v2--flex-45 {
  -webkit-box-flex: 45;
  -webkit-flex: 45;
  -ms-flex: 45;
  flex: 45;
}

.react-flex-v2--flex-46 {
  -webkit-box-flex: 46;
  -webkit-flex: 46;
  -ms-flex: 46;
  flex: 46;
}

.react-flex-v2--flex-47 {
  -webkit-box-flex: 47;
  -webkit-flex: 47;
  -ms-flex: 47;
  flex: 47;
}

.react-flex-v2--flex-48 {
  -webkit-box-flex: 48;
  -webkit-flex: 48;
  -ms-flex: 48;
  flex: 48;
}

.react-flex-v2--flex-49 {
  -webkit-box-flex: 49;
  -webkit-flex: 49;
  -ms-flex: 49;
  flex: 49;
}

.react-flex-v2--flex-50 {
  -webkit-box-flex: 50;
  -webkit-flex: 50;
  -ms-flex: 50;
  flex: 50;
}

.react-flex-v2--flex-51 {
  -webkit-box-flex: 51;
  -webkit-flex: 51;
  -ms-flex: 51;
  flex: 51;
}

.react-flex-v2--flex-52 {
  -webkit-box-flex: 52;
  -webkit-flex: 52;
  -ms-flex: 52;
  flex: 52;
}

.react-flex-v2--flex-53 {
  -webkit-box-flex: 53;
  -webkit-flex: 53;
  -ms-flex: 53;
  flex: 53;
}

.react-flex-v2--flex-54 {
  -webkit-box-flex: 54;
  -webkit-flex: 54;
  -ms-flex: 54;
  flex: 54;
}

.react-flex-v2--flex-55 {
  -webkit-box-flex: 55;
  -webkit-flex: 55;
  -ms-flex: 55;
  flex: 55;
}

.react-flex-v2--flex-56 {
  -webkit-box-flex: 56;
  -webkit-flex: 56;
  -ms-flex: 56;
  flex: 56;
}

.react-flex-v2--flex-57 {
  -webkit-box-flex: 57;
  -webkit-flex: 57;
  -ms-flex: 57;
  flex: 57;
}

.react-flex-v2--flex-58 {
  -webkit-box-flex: 58;
  -webkit-flex: 58;
  -ms-flex: 58;
  flex: 58;
}

.react-flex-v2--flex-59 {
  -webkit-box-flex: 59;
  -webkit-flex: 59;
  -ms-flex: 59;
  flex: 59;
}

.react-flex-v2--flex-60 {
  -webkit-box-flex: 60;
  -webkit-flex: 60;
  -ms-flex: 60;
  flex: 60;
}

.react-flex-v2--flex-61 {
  -webkit-box-flex: 61;
  -webkit-flex: 61;
  -ms-flex: 61;
  flex: 61;
}

.react-flex-v2--flex-62 {
  -webkit-box-flex: 62;
  -webkit-flex: 62;
  -ms-flex: 62;
  flex: 62;
}

.react-flex-v2--flex-63 {
  -webkit-box-flex: 63;
  -webkit-flex: 63;
  -ms-flex: 63;
  flex: 63;
}

.react-flex-v2--flex-64 {
  -webkit-box-flex: 64;
  -webkit-flex: 64;
  -ms-flex: 64;
  flex: 64;
}

.react-flex-v2--flex-65 {
  -webkit-box-flex: 65;
  -webkit-flex: 65;
  -ms-flex: 65;
  flex: 65;
}

.react-flex-v2--flex-66 {
  -webkit-box-flex: 66;
  -webkit-flex: 66;
  -ms-flex: 66;
  flex: 66;
}

.react-flex-v2--flex-67 {
  -webkit-box-flex: 67;
  -webkit-flex: 67;
  -ms-flex: 67;
  flex: 67;
}

.react-flex-v2--flex-68 {
  -webkit-box-flex: 68;
  -webkit-flex: 68;
  -ms-flex: 68;
  flex: 68;
}

.react-flex-v2--flex-69 {
  -webkit-box-flex: 69;
  -webkit-flex: 69;
  -ms-flex: 69;
  flex: 69;
}

.react-flex-v2--flex-70 {
  -webkit-box-flex: 70;
  -webkit-flex: 70;
  -ms-flex: 70;
  flex: 70;
}

.react-flex-v2--flex-71 {
  -webkit-box-flex: 71;
  -webkit-flex: 71;
  -ms-flex: 71;
  flex: 71;
}

.react-flex-v2--flex-72 {
  -webkit-box-flex: 72;
  -webkit-flex: 72;
  -ms-flex: 72;
  flex: 72;
}

.react-flex-v2--flex-73 {
  -webkit-box-flex: 73;
  -webkit-flex: 73;
  -ms-flex: 73;
  flex: 73;
}

.react-flex-v2--flex-74 {
  -webkit-box-flex: 74;
  -webkit-flex: 74;
  -ms-flex: 74;
  flex: 74;
}

.react-flex-v2--flex-75 {
  -webkit-box-flex: 75;
  -webkit-flex: 75;
  -ms-flex: 75;
  flex: 75;
}

.react-flex-v2--flex-76 {
  -webkit-box-flex: 76;
  -webkit-flex: 76;
  -ms-flex: 76;
  flex: 76;
}

.react-flex-v2--flex-77 {
  -webkit-box-flex: 77;
  -webkit-flex: 77;
  -ms-flex: 77;
  flex: 77;
}

.react-flex-v2--flex-78 {
  -webkit-box-flex: 78;
  -webkit-flex: 78;
  -ms-flex: 78;
  flex: 78;
}

.react-flex-v2--flex-79 {
  -webkit-box-flex: 79;
  -webkit-flex: 79;
  -ms-flex: 79;
  flex: 79;
}

.react-flex-v2--flex-80 {
  -webkit-box-flex: 80;
  -webkit-flex: 80;
  -ms-flex: 80;
  flex: 80;
}

.react-flex-v2--flex-81 {
  -webkit-box-flex: 81;
  -webkit-flex: 81;
  -ms-flex: 81;
  flex: 81;
}

.react-flex-v2--flex-82 {
  -webkit-box-flex: 82;
  -webkit-flex: 82;
  -ms-flex: 82;
  flex: 82;
}

.react-flex-v2--flex-83 {
  -webkit-box-flex: 83;
  -webkit-flex: 83;
  -ms-flex: 83;
  flex: 83;
}

.react-flex-v2--flex-84 {
  -webkit-box-flex: 84;
  -webkit-flex: 84;
  -ms-flex: 84;
  flex: 84;
}

.react-flex-v2--flex-85 {
  -webkit-box-flex: 85;
  -webkit-flex: 85;
  -ms-flex: 85;
  flex: 85;
}

.react-flex-v2--flex-86 {
  -webkit-box-flex: 86;
  -webkit-flex: 86;
  -ms-flex: 86;
  flex: 86;
}

.react-flex-v2--flex-87 {
  -webkit-box-flex: 87;
  -webkit-flex: 87;
  -ms-flex: 87;
  flex: 87;
}

.react-flex-v2--flex-88 {
  -webkit-box-flex: 88;
  -webkit-flex: 88;
  -ms-flex: 88;
  flex: 88;
}

.react-flex-v2--flex-89 {
  -webkit-box-flex: 89;
  -webkit-flex: 89;
  -ms-flex: 89;
  flex: 89;
}

.react-flex-v2--flex-90 {
  -webkit-box-flex: 90;
  -webkit-flex: 90;
  -ms-flex: 90;
  flex: 90;
}

.react-flex-v2--flex-91 {
  -webkit-box-flex: 91;
  -webkit-flex: 91;
  -ms-flex: 91;
  flex: 91;
}

.react-flex-v2--flex-92 {
  -webkit-box-flex: 92;
  -webkit-flex: 92;
  -ms-flex: 92;
  flex: 92;
}

.react-flex-v2--flex-93 {
  -webkit-box-flex: 93;
  -webkit-flex: 93;
  -ms-flex: 93;
  flex: 93;
}

.react-flex-v2--flex-94 {
  -webkit-box-flex: 94;
  -webkit-flex: 94;
  -ms-flex: 94;
  flex: 94;
}

.react-flex-v2--flex-95 {
  -webkit-box-flex: 95;
  -webkit-flex: 95;
  -ms-flex: 95;
  flex: 95;
}

.react-flex-v2--flex-96 {
  -webkit-box-flex: 96;
  -webkit-flex: 96;
  -ms-flex: 96;
  flex: 96;
}

.react-flex-v2--flex-97 {
  -webkit-box-flex: 97;
  -webkit-flex: 97;
  -ms-flex: 97;
  flex: 97;
}

.react-flex-v2--flex-98 {
  -webkit-box-flex: 98;
  -webkit-flex: 98;
  -ms-flex: 98;
  flex: 98;
}

.react-flex-v2--flex-99 {
  -webkit-box-flex: 99;
  -webkit-flex: 99;
  -ms-flex: 99;
  flex: 99;
}

.react-flex-v2--flex-100 {
  -webkit-box-flex: 100;
  -webkit-flex: 100;
  -ms-flex: 100;
  flex: 100;
}

/* FLEX-GROW */

.react-flex-v2--flex-grow-0 {
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}

.react-flex-v2--flex-grow-1 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.react-flex-v2--flex-grow-2 {
  -webkit-box-flex: 2;
  -webkit-flex-grow: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.react-flex-v2--flex-grow-3 {
  -webkit-box-flex: 3;
  -webkit-flex-grow: 3;
  -ms-flex-positive: 3;
  flex-grow: 3;
}

.react-flex-v2--flex-grow-4 {
  -webkit-box-flex: 4;
  -webkit-flex-grow: 4;
  -ms-flex-positive: 4;
  flex-grow: 4;
}

.react-flex-v2--flex-grow-5 {
  -webkit-box-flex: 5;
  -webkit-flex-grow: 5;
  -ms-flex-positive: 5;
  flex-grow: 5;
}

.react-flex-v2--flex-grow-6 {
  -webkit-box-flex: 6;
  -webkit-flex-grow: 6;
  -ms-flex-positive: 6;
  flex-grow: 6;
}

.react-flex-v2--flex-grow-7 {
  -webkit-box-flex: 7;
  -webkit-flex-grow: 7;
  -ms-flex-positive: 7;
  flex-grow: 7;
}

.react-flex-v2--flex-grow-8 {
  -webkit-box-flex: 8;
  -webkit-flex-grow: 8;
  -ms-flex-positive: 8;
  flex-grow: 8;
}

.react-flex-v2--flex-grow-9 {
  -webkit-box-flex: 9;
  -webkit-flex-grow: 9;
  -ms-flex-positive: 9;
  flex-grow: 9;
}

.react-flex-v2--flex-grow-10 {
  -webkit-box-flex: 10;
  -webkit-flex-grow: 10;
  -ms-flex-positive: 10;
  flex-grow: 10;
}

.react-flex-v2--flex-grow-11 {
  -webkit-box-flex: 11;
  -webkit-flex-grow: 11;
  -ms-flex-positive: 11;
  flex-grow: 11;
}

.react-flex-v2--flex-grow-12 {
  -webkit-box-flex: 12;
  -webkit-flex-grow: 12;
  -ms-flex-positive: 12;
  flex-grow: 12;
}

.react-flex-v2--flex-grow-13 {
  -webkit-box-flex: 13;
  -webkit-flex-grow: 13;
  -ms-flex-positive: 13;
  flex-grow: 13;
}

.react-flex-v2--flex-grow-14 {
  -webkit-box-flex: 14;
  -webkit-flex-grow: 14;
  -ms-flex-positive: 14;
  flex-grow: 14;
}

.react-flex-v2--flex-grow-15 {
  -webkit-box-flex: 15;
  -webkit-flex-grow: 15;
  -ms-flex-positive: 15;
  flex-grow: 15;
}

.react-flex-v2--flex-grow-16 {
  -webkit-box-flex: 16;
  -webkit-flex-grow: 16;
  -ms-flex-positive: 16;
  flex-grow: 16;
}

.react-flex-v2--flex-grow-17 {
  -webkit-box-flex: 17;
  -webkit-flex-grow: 17;
  -ms-flex-positive: 17;
  flex-grow: 17;
}

.react-flex-v2--flex-grow-18 {
  -webkit-box-flex: 18;
  -webkit-flex-grow: 18;
  -ms-flex-positive: 18;
  flex-grow: 18;
}

.react-flex-v2--flex-grow-19 {
  -webkit-box-flex: 19;
  -webkit-flex-grow: 19;
  -ms-flex-positive: 19;
  flex-grow: 19;
}

.react-flex-v2--flex-grow-20 {
  -webkit-box-flex: 20;
  -webkit-flex-grow: 20;
  -ms-flex-positive: 20;
  flex-grow: 20;
}

.react-flex-v2--flex-grow-21 {
  -webkit-box-flex: 21;
  -webkit-flex-grow: 21;
  -ms-flex-positive: 21;
  flex-grow: 21;
}

.react-flex-v2--flex-grow-22 {
  -webkit-box-flex: 22;
  -webkit-flex-grow: 22;
  -ms-flex-positive: 22;
  flex-grow: 22;
}

.react-flex-v2--flex-grow-23 {
  -webkit-box-flex: 23;
  -webkit-flex-grow: 23;
  -ms-flex-positive: 23;
  flex-grow: 23;
}

.react-flex-v2--flex-grow-24 {
  -webkit-box-flex: 24;
  -webkit-flex-grow: 24;
  -ms-flex-positive: 24;
  flex-grow: 24;
}

.react-flex-v2--flex-grow-25 {
  -webkit-box-flex: 25;
  -webkit-flex-grow: 25;
  -ms-flex-positive: 25;
  flex-grow: 25;
}

.react-flex-v2--flex-grow-26 {
  -webkit-box-flex: 26;
  -webkit-flex-grow: 26;
  -ms-flex-positive: 26;
  flex-grow: 26;
}

.react-flex-v2--flex-grow-27 {
  -webkit-box-flex: 27;
  -webkit-flex-grow: 27;
  -ms-flex-positive: 27;
  flex-grow: 27;
}

.react-flex-v2--flex-grow-28 {
  -webkit-box-flex: 28;
  -webkit-flex-grow: 28;
  -ms-flex-positive: 28;
  flex-grow: 28;
}

.react-flex-v2--flex-grow-29 {
  -webkit-box-flex: 29;
  -webkit-flex-grow: 29;
  -ms-flex-positive: 29;
  flex-grow: 29;
}

.react-flex-v2--flex-grow-30 {
  -webkit-box-flex: 30;
  -webkit-flex-grow: 30;
  -ms-flex-positive: 30;
  flex-grow: 30;
}

.react-flex-v2--flex-grow-31 {
  -webkit-box-flex: 31;
  -webkit-flex-grow: 31;
  -ms-flex-positive: 31;
  flex-grow: 31;
}

.react-flex-v2--flex-grow-32 {
  -webkit-box-flex: 32;
  -webkit-flex-grow: 32;
  -ms-flex-positive: 32;
  flex-grow: 32;
}

.react-flex-v2--flex-grow-33 {
  -webkit-box-flex: 33;
  -webkit-flex-grow: 33;
  -ms-flex-positive: 33;
  flex-grow: 33;
}

.react-flex-v2--flex-grow-34 {
  -webkit-box-flex: 34;
  -webkit-flex-grow: 34;
  -ms-flex-positive: 34;
  flex-grow: 34;
}

.react-flex-v2--flex-grow-35 {
  -webkit-box-flex: 35;
  -webkit-flex-grow: 35;
  -ms-flex-positive: 35;
  flex-grow: 35;
}

.react-flex-v2--flex-grow-36 {
  -webkit-box-flex: 36;
  -webkit-flex-grow: 36;
  -ms-flex-positive: 36;
  flex-grow: 36;
}

.react-flex-v2--flex-grow-37 {
  -webkit-box-flex: 37;
  -webkit-flex-grow: 37;
  -ms-flex-positive: 37;
  flex-grow: 37;
}

.react-flex-v2--flex-grow-38 {
  -webkit-box-flex: 38;
  -webkit-flex-grow: 38;
  -ms-flex-positive: 38;
  flex-grow: 38;
}

.react-flex-v2--flex-grow-39 {
  -webkit-box-flex: 39;
  -webkit-flex-grow: 39;
  -ms-flex-positive: 39;
  flex-grow: 39;
}

.react-flex-v2--flex-grow-40 {
  -webkit-box-flex: 40;
  -webkit-flex-grow: 40;
  -ms-flex-positive: 40;
  flex-grow: 40;
}

.react-flex-v2--flex-grow-41 {
  -webkit-box-flex: 41;
  -webkit-flex-grow: 41;
  -ms-flex-positive: 41;
  flex-grow: 41;
}

.react-flex-v2--flex-grow-42 {
  -webkit-box-flex: 42;
  -webkit-flex-grow: 42;
  -ms-flex-positive: 42;
  flex-grow: 42;
}

.react-flex-v2--flex-grow-43 {
  -webkit-box-flex: 43;
  -webkit-flex-grow: 43;
  -ms-flex-positive: 43;
  flex-grow: 43;
}

.react-flex-v2--flex-grow-44 {
  -webkit-box-flex: 44;
  -webkit-flex-grow: 44;
  -ms-flex-positive: 44;
  flex-grow: 44;
}

.react-flex-v2--flex-grow-45 {
  -webkit-box-flex: 45;
  -webkit-flex-grow: 45;
  -ms-flex-positive: 45;
  flex-grow: 45;
}

.react-flex-v2--flex-grow-46 {
  -webkit-box-flex: 46;
  -webkit-flex-grow: 46;
  -ms-flex-positive: 46;
  flex-grow: 46;
}

.react-flex-v2--flex-grow-47 {
  -webkit-box-flex: 47;
  -webkit-flex-grow: 47;
  -ms-flex-positive: 47;
  flex-grow: 47;
}

.react-flex-v2--flex-grow-48 {
  -webkit-box-flex: 48;
  -webkit-flex-grow: 48;
  -ms-flex-positive: 48;
  flex-grow: 48;
}

.react-flex-v2--flex-grow-49 {
  -webkit-box-flex: 49;
  -webkit-flex-grow: 49;
  -ms-flex-positive: 49;
  flex-grow: 49;
}

.react-flex-v2--flex-grow-50 {
  -webkit-box-flex: 50;
  -webkit-flex-grow: 50;
  -ms-flex-positive: 50;
  flex-grow: 50;
}

.react-flex-v2--flex-grow-51 {
  -webkit-box-flex: 51;
  -webkit-flex-grow: 51;
  -ms-flex-positive: 51;
  flex-grow: 51;
}

.react-flex-v2--flex-grow-52 {
  -webkit-box-flex: 52;
  -webkit-flex-grow: 52;
  -ms-flex-positive: 52;
  flex-grow: 52;
}

.react-flex-v2--flex-grow-53 {
  -webkit-box-flex: 53;
  -webkit-flex-grow: 53;
  -ms-flex-positive: 53;
  flex-grow: 53;
}

.react-flex-v2--flex-grow-54 {
  -webkit-box-flex: 54;
  -webkit-flex-grow: 54;
  -ms-flex-positive: 54;
  flex-grow: 54;
}

.react-flex-v2--flex-grow-55 {
  -webkit-box-flex: 55;
  -webkit-flex-grow: 55;
  -ms-flex-positive: 55;
  flex-grow: 55;
}

.react-flex-v2--flex-grow-56 {
  -webkit-box-flex: 56;
  -webkit-flex-grow: 56;
  -ms-flex-positive: 56;
  flex-grow: 56;
}

.react-flex-v2--flex-grow-57 {
  -webkit-box-flex: 57;
  -webkit-flex-grow: 57;
  -ms-flex-positive: 57;
  flex-grow: 57;
}

.react-flex-v2--flex-grow-58 {
  -webkit-box-flex: 58;
  -webkit-flex-grow: 58;
  -ms-flex-positive: 58;
  flex-grow: 58;
}

.react-flex-v2--flex-grow-59 {
  -webkit-box-flex: 59;
  -webkit-flex-grow: 59;
  -ms-flex-positive: 59;
  flex-grow: 59;
}

.react-flex-v2--flex-grow-60 {
  -webkit-box-flex: 60;
  -webkit-flex-grow: 60;
  -ms-flex-positive: 60;
  flex-grow: 60;
}

.react-flex-v2--flex-grow-61 {
  -webkit-box-flex: 61;
  -webkit-flex-grow: 61;
  -ms-flex-positive: 61;
  flex-grow: 61;
}

.react-flex-v2--flex-grow-62 {
  -webkit-box-flex: 62;
  -webkit-flex-grow: 62;
  -ms-flex-positive: 62;
  flex-grow: 62;
}

.react-flex-v2--flex-grow-63 {
  -webkit-box-flex: 63;
  -webkit-flex-grow: 63;
  -ms-flex-positive: 63;
  flex-grow: 63;
}

.react-flex-v2--flex-grow-64 {
  -webkit-box-flex: 64;
  -webkit-flex-grow: 64;
  -ms-flex-positive: 64;
  flex-grow: 64;
}

.react-flex-v2--flex-grow-65 {
  -webkit-box-flex: 65;
  -webkit-flex-grow: 65;
  -ms-flex-positive: 65;
  flex-grow: 65;
}

.react-flex-v2--flex-grow-66 {
  -webkit-box-flex: 66;
  -webkit-flex-grow: 66;
  -ms-flex-positive: 66;
  flex-grow: 66;
}

.react-flex-v2--flex-grow-67 {
  -webkit-box-flex: 67;
  -webkit-flex-grow: 67;
  -ms-flex-positive: 67;
  flex-grow: 67;
}

.react-flex-v2--flex-grow-68 {
  -webkit-box-flex: 68;
  -webkit-flex-grow: 68;
  -ms-flex-positive: 68;
  flex-grow: 68;
}

.react-flex-v2--flex-grow-69 {
  -webkit-box-flex: 69;
  -webkit-flex-grow: 69;
  -ms-flex-positive: 69;
  flex-grow: 69;
}

.react-flex-v2--flex-grow-70 {
  -webkit-box-flex: 70;
  -webkit-flex-grow: 70;
  -ms-flex-positive: 70;
  flex-grow: 70;
}

.react-flex-v2--flex-grow-71 {
  -webkit-box-flex: 71;
  -webkit-flex-grow: 71;
  -ms-flex-positive: 71;
  flex-grow: 71;
}

.react-flex-v2--flex-grow-72 {
  -webkit-box-flex: 72;
  -webkit-flex-grow: 72;
  -ms-flex-positive: 72;
  flex-grow: 72;
}

.react-flex-v2--flex-grow-73 {
  -webkit-box-flex: 73;
  -webkit-flex-grow: 73;
  -ms-flex-positive: 73;
  flex-grow: 73;
}

.react-flex-v2--flex-grow-74 {
  -webkit-box-flex: 74;
  -webkit-flex-grow: 74;
  -ms-flex-positive: 74;
  flex-grow: 74;
}

.react-flex-v2--flex-grow-75 {
  -webkit-box-flex: 75;
  -webkit-flex-grow: 75;
  -ms-flex-positive: 75;
  flex-grow: 75;
}

.react-flex-v2--flex-grow-76 {
  -webkit-box-flex: 76;
  -webkit-flex-grow: 76;
  -ms-flex-positive: 76;
  flex-grow: 76;
}

.react-flex-v2--flex-grow-77 {
  -webkit-box-flex: 77;
  -webkit-flex-grow: 77;
  -ms-flex-positive: 77;
  flex-grow: 77;
}

.react-flex-v2--flex-grow-78 {
  -webkit-box-flex: 78;
  -webkit-flex-grow: 78;
  -ms-flex-positive: 78;
  flex-grow: 78;
}

.react-flex-v2--flex-grow-79 {
  -webkit-box-flex: 79;
  -webkit-flex-grow: 79;
  -ms-flex-positive: 79;
  flex-grow: 79;
}

.react-flex-v2--flex-grow-80 {
  -webkit-box-flex: 80;
  -webkit-flex-grow: 80;
  -ms-flex-positive: 80;
  flex-grow: 80;
}

.react-flex-v2--flex-grow-81 {
  -webkit-box-flex: 81;
  -webkit-flex-grow: 81;
  -ms-flex-positive: 81;
  flex-grow: 81;
}

.react-flex-v2--flex-grow-82 {
  -webkit-box-flex: 82;
  -webkit-flex-grow: 82;
  -ms-flex-positive: 82;
  flex-grow: 82;
}

.react-flex-v2--flex-grow-83 {
  -webkit-box-flex: 83;
  -webkit-flex-grow: 83;
  -ms-flex-positive: 83;
  flex-grow: 83;
}

.react-flex-v2--flex-grow-84 {
  -webkit-box-flex: 84;
  -webkit-flex-grow: 84;
  -ms-flex-positive: 84;
  flex-grow: 84;
}

.react-flex-v2--flex-grow-85 {
  -webkit-box-flex: 85;
  -webkit-flex-grow: 85;
  -ms-flex-positive: 85;
  flex-grow: 85;
}

.react-flex-v2--flex-grow-86 {
  -webkit-box-flex: 86;
  -webkit-flex-grow: 86;
  -ms-flex-positive: 86;
  flex-grow: 86;
}

.react-flex-v2--flex-grow-87 {
  -webkit-box-flex: 87;
  -webkit-flex-grow: 87;
  -ms-flex-positive: 87;
  flex-grow: 87;
}

.react-flex-v2--flex-grow-88 {
  -webkit-box-flex: 88;
  -webkit-flex-grow: 88;
  -ms-flex-positive: 88;
  flex-grow: 88;
}

.react-flex-v2--flex-grow-89 {
  -webkit-box-flex: 89;
  -webkit-flex-grow: 89;
  -ms-flex-positive: 89;
  flex-grow: 89;
}

.react-flex-v2--flex-grow-90 {
  -webkit-box-flex: 90;
  -webkit-flex-grow: 90;
  -ms-flex-positive: 90;
  flex-grow: 90;
}

.react-flex-v2--flex-grow-91 {
  -webkit-box-flex: 91;
  -webkit-flex-grow: 91;
  -ms-flex-positive: 91;
  flex-grow: 91;
}

.react-flex-v2--flex-grow-92 {
  -webkit-box-flex: 92;
  -webkit-flex-grow: 92;
  -ms-flex-positive: 92;
  flex-grow: 92;
}

.react-flex-v2--flex-grow-93 {
  -webkit-box-flex: 93;
  -webkit-flex-grow: 93;
  -ms-flex-positive: 93;
  flex-grow: 93;
}

.react-flex-v2--flex-grow-94 {
  -webkit-box-flex: 94;
  -webkit-flex-grow: 94;
  -ms-flex-positive: 94;
  flex-grow: 94;
}

.react-flex-v2--flex-grow-95 {
  -webkit-box-flex: 95;
  -webkit-flex-grow: 95;
  -ms-flex-positive: 95;
  flex-grow: 95;
}

.react-flex-v2--flex-grow-96 {
  -webkit-box-flex: 96;
  -webkit-flex-grow: 96;
  -ms-flex-positive: 96;
  flex-grow: 96;
}

.react-flex-v2--flex-grow-97 {
  -webkit-box-flex: 97;
  -webkit-flex-grow: 97;
  -ms-flex-positive: 97;
  flex-grow: 97;
}

.react-flex-v2--flex-grow-98 {
  -webkit-box-flex: 98;
  -webkit-flex-grow: 98;
  -ms-flex-positive: 98;
  flex-grow: 98;
}

.react-flex-v2--flex-grow-99 {
  -webkit-box-flex: 99;
  -webkit-flex-grow: 99;
  -ms-flex-positive: 99;
  flex-grow: 99;
}

.react-flex-v2--flex-grow-100 {
  -webkit-box-flex: 100;
  -webkit-flex-grow: 100;
  -ms-flex-positive: 100;
  flex-grow: 100;
}

/* FLEX-SHRINK */

.react-flex-v2--flex-shrink-0 {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.react-flex-v2--flex-shrink-1 {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}

.react-flex-v2--flex-shrink-2 {
  -webkit-flex-shrink: 2;
  -ms-flex-negative: 2;
  flex-shrink: 2;
}

.react-flex-v2--flex-shrink-3 {
  -webkit-flex-shrink: 3;
  -ms-flex-negative: 3;
  flex-shrink: 3;
}

.react-flex-v2--flex-shrink-4 {
  -webkit-flex-shrink: 4;
  -ms-flex-negative: 4;
  flex-shrink: 4;
}

.react-flex-v2--flex-shrink-5 {
  -webkit-flex-shrink: 5;
  -ms-flex-negative: 5;
  flex-shrink: 5;
}

.react-flex-v2--flex-shrink-6 {
  -webkit-flex-shrink: 6;
  -ms-flex-negative: 6;
  flex-shrink: 6;
}

.react-flex-v2--flex-shrink-7 {
  -webkit-flex-shrink: 7;
  -ms-flex-negative: 7;
  flex-shrink: 7;
}

.react-flex-v2--flex-shrink-8 {
  -webkit-flex-shrink: 8;
  -ms-flex-negative: 8;
  flex-shrink: 8;
}

.react-flex-v2--flex-shrink-9 {
  -webkit-flex-shrink: 9;
  -ms-flex-negative: 9;
  flex-shrink: 9;
}

.react-flex-v2--flex-shrink-10 {
  -webkit-flex-shrink: 10;
  -ms-flex-negative: 10;
  flex-shrink: 10;
}

.react-flex-v2--flex-shrink-11 {
  -webkit-flex-shrink: 11;
  -ms-flex-negative: 11;
  flex-shrink: 11;
}

.react-flex-v2--flex-shrink-12 {
  -webkit-flex-shrink: 12;
  -ms-flex-negative: 12;
  flex-shrink: 12;
}

.react-flex-v2--flex-shrink-13 {
  -webkit-flex-shrink: 13;
  -ms-flex-negative: 13;
  flex-shrink: 13;
}

.react-flex-v2--flex-shrink-14 {
  -webkit-flex-shrink: 14;
  -ms-flex-negative: 14;
  flex-shrink: 14;
}

.react-flex-v2--flex-shrink-15 {
  -webkit-flex-shrink: 15;
  -ms-flex-negative: 15;
  flex-shrink: 15;
}

.react-flex-v2--flex-shrink-16 {
  -webkit-flex-shrink: 16;
  -ms-flex-negative: 16;
  flex-shrink: 16;
}

.react-flex-v2--flex-shrink-17 {
  -webkit-flex-shrink: 17;
  -ms-flex-negative: 17;
  flex-shrink: 17;
}

.react-flex-v2--flex-shrink-18 {
  -webkit-flex-shrink: 18;
  -ms-flex-negative: 18;
  flex-shrink: 18;
}

.react-flex-v2--flex-shrink-19 {
  -webkit-flex-shrink: 19;
  -ms-flex-negative: 19;
  flex-shrink: 19;
}

.react-flex-v2--flex-shrink-20 {
  -webkit-flex-shrink: 20;
  -ms-flex-negative: 20;
  flex-shrink: 20;
}

.react-flex-v2--flex-shrink-21 {
  -webkit-flex-shrink: 21;
  -ms-flex-negative: 21;
  flex-shrink: 21;
}

.react-flex-v2--flex-shrink-22 {
  -webkit-flex-shrink: 22;
  -ms-flex-negative: 22;
  flex-shrink: 22;
}

.react-flex-v2--flex-shrink-23 {
  -webkit-flex-shrink: 23;
  -ms-flex-negative: 23;
  flex-shrink: 23;
}

.react-flex-v2--flex-shrink-24 {
  -webkit-flex-shrink: 24;
  -ms-flex-negative: 24;
  flex-shrink: 24;
}

.react-flex-v2--flex-shrink-25 {
  -webkit-flex-shrink: 25;
  -ms-flex-negative: 25;
  flex-shrink: 25;
}

.react-flex-v2--flex-shrink-26 {
  -webkit-flex-shrink: 26;
  -ms-flex-negative: 26;
  flex-shrink: 26;
}

.react-flex-v2--flex-shrink-27 {
  -webkit-flex-shrink: 27;
  -ms-flex-negative: 27;
  flex-shrink: 27;
}

.react-flex-v2--flex-shrink-28 {
  -webkit-flex-shrink: 28;
  -ms-flex-negative: 28;
  flex-shrink: 28;
}

.react-flex-v2--flex-shrink-29 {
  -webkit-flex-shrink: 29;
  -ms-flex-negative: 29;
  flex-shrink: 29;
}

.react-flex-v2--flex-shrink-30 {
  -webkit-flex-shrink: 30;
  -ms-flex-negative: 30;
  flex-shrink: 30;
}

.react-flex-v2--flex-shrink-31 {
  -webkit-flex-shrink: 31;
  -ms-flex-negative: 31;
  flex-shrink: 31;
}

.react-flex-v2--flex-shrink-32 {
  -webkit-flex-shrink: 32;
  -ms-flex-negative: 32;
  flex-shrink: 32;
}

.react-flex-v2--flex-shrink-33 {
  -webkit-flex-shrink: 33;
  -ms-flex-negative: 33;
  flex-shrink: 33;
}

.react-flex-v2--flex-shrink-34 {
  -webkit-flex-shrink: 34;
  -ms-flex-negative: 34;
  flex-shrink: 34;
}

.react-flex-v2--flex-shrink-35 {
  -webkit-flex-shrink: 35;
  -ms-flex-negative: 35;
  flex-shrink: 35;
}

.react-flex-v2--flex-shrink-36 {
  -webkit-flex-shrink: 36;
  -ms-flex-negative: 36;
  flex-shrink: 36;
}

.react-flex-v2--flex-shrink-37 {
  -webkit-flex-shrink: 37;
  -ms-flex-negative: 37;
  flex-shrink: 37;
}

.react-flex-v2--flex-shrink-38 {
  -webkit-flex-shrink: 38;
  -ms-flex-negative: 38;
  flex-shrink: 38;
}

.react-flex-v2--flex-shrink-39 {
  -webkit-flex-shrink: 39;
  -ms-flex-negative: 39;
  flex-shrink: 39;
}

.react-flex-v2--flex-shrink-40 {
  -webkit-flex-shrink: 40;
  -ms-flex-negative: 40;
  flex-shrink: 40;
}

.react-flex-v2--flex-shrink-41 {
  -webkit-flex-shrink: 41;
  -ms-flex-negative: 41;
  flex-shrink: 41;
}

.react-flex-v2--flex-shrink-42 {
  -webkit-flex-shrink: 42;
  -ms-flex-negative: 42;
  flex-shrink: 42;
}

.react-flex-v2--flex-shrink-43 {
  -webkit-flex-shrink: 43;
  -ms-flex-negative: 43;
  flex-shrink: 43;
}

.react-flex-v2--flex-shrink-44 {
  -webkit-flex-shrink: 44;
  -ms-flex-negative: 44;
  flex-shrink: 44;
}

.react-flex-v2--flex-shrink-45 {
  -webkit-flex-shrink: 45;
  -ms-flex-negative: 45;
  flex-shrink: 45;
}

.react-flex-v2--flex-shrink-46 {
  -webkit-flex-shrink: 46;
  -ms-flex-negative: 46;
  flex-shrink: 46;
}

.react-flex-v2--flex-shrink-47 {
  -webkit-flex-shrink: 47;
  -ms-flex-negative: 47;
  flex-shrink: 47;
}

.react-flex-v2--flex-shrink-48 {
  -webkit-flex-shrink: 48;
  -ms-flex-negative: 48;
  flex-shrink: 48;
}

.react-flex-v2--flex-shrink-49 {
  -webkit-flex-shrink: 49;
  -ms-flex-negative: 49;
  flex-shrink: 49;
}

.react-flex-v2--flex-shrink-50 {
  -webkit-flex-shrink: 50;
  -ms-flex-negative: 50;
  flex-shrink: 50;
}

.react-flex-v2--flex-shrink-51 {
  -webkit-flex-shrink: 51;
  -ms-flex-negative: 51;
  flex-shrink: 51;
}

.react-flex-v2--flex-shrink-52 {
  -webkit-flex-shrink: 52;
  -ms-flex-negative: 52;
  flex-shrink: 52;
}

.react-flex-v2--flex-shrink-53 {
  -webkit-flex-shrink: 53;
  -ms-flex-negative: 53;
  flex-shrink: 53;
}

.react-flex-v2--flex-shrink-54 {
  -webkit-flex-shrink: 54;
  -ms-flex-negative: 54;
  flex-shrink: 54;
}

.react-flex-v2--flex-shrink-55 {
  -webkit-flex-shrink: 55;
  -ms-flex-negative: 55;
  flex-shrink: 55;
}

.react-flex-v2--flex-shrink-56 {
  -webkit-flex-shrink: 56;
  -ms-flex-negative: 56;
  flex-shrink: 56;
}

.react-flex-v2--flex-shrink-57 {
  -webkit-flex-shrink: 57;
  -ms-flex-negative: 57;
  flex-shrink: 57;
}

.react-flex-v2--flex-shrink-58 {
  -webkit-flex-shrink: 58;
  -ms-flex-negative: 58;
  flex-shrink: 58;
}

.react-flex-v2--flex-shrink-59 {
  -webkit-flex-shrink: 59;
  -ms-flex-negative: 59;
  flex-shrink: 59;
}

.react-flex-v2--flex-shrink-60 {
  -webkit-flex-shrink: 60;
  -ms-flex-negative: 60;
  flex-shrink: 60;
}

.react-flex-v2--flex-shrink-61 {
  -webkit-flex-shrink: 61;
  -ms-flex-negative: 61;
  flex-shrink: 61;
}

.react-flex-v2--flex-shrink-62 {
  -webkit-flex-shrink: 62;
  -ms-flex-negative: 62;
  flex-shrink: 62;
}

.react-flex-v2--flex-shrink-63 {
  -webkit-flex-shrink: 63;
  -ms-flex-negative: 63;
  flex-shrink: 63;
}

.react-flex-v2--flex-shrink-64 {
  -webkit-flex-shrink: 64;
  -ms-flex-negative: 64;
  flex-shrink: 64;
}

.react-flex-v2--flex-shrink-65 {
  -webkit-flex-shrink: 65;
  -ms-flex-negative: 65;
  flex-shrink: 65;
}

.react-flex-v2--flex-shrink-66 {
  -webkit-flex-shrink: 66;
  -ms-flex-negative: 66;
  flex-shrink: 66;
}

.react-flex-v2--flex-shrink-67 {
  -webkit-flex-shrink: 67;
  -ms-flex-negative: 67;
  flex-shrink: 67;
}

.react-flex-v2--flex-shrink-68 {
  -webkit-flex-shrink: 68;
  -ms-flex-negative: 68;
  flex-shrink: 68;
}

.react-flex-v2--flex-shrink-69 {
  -webkit-flex-shrink: 69;
  -ms-flex-negative: 69;
  flex-shrink: 69;
}

.react-flex-v2--flex-shrink-70 {
  -webkit-flex-shrink: 70;
  -ms-flex-negative: 70;
  flex-shrink: 70;
}

.react-flex-v2--flex-shrink-71 {
  -webkit-flex-shrink: 71;
  -ms-flex-negative: 71;
  flex-shrink: 71;
}

.react-flex-v2--flex-shrink-72 {
  -webkit-flex-shrink: 72;
  -ms-flex-negative: 72;
  flex-shrink: 72;
}

.react-flex-v2--flex-shrink-73 {
  -webkit-flex-shrink: 73;
  -ms-flex-negative: 73;
  flex-shrink: 73;
}

.react-flex-v2--flex-shrink-74 {
  -webkit-flex-shrink: 74;
  -ms-flex-negative: 74;
  flex-shrink: 74;
}

.react-flex-v2--flex-shrink-75 {
  -webkit-flex-shrink: 75;
  -ms-flex-negative: 75;
  flex-shrink: 75;
}

.react-flex-v2--flex-shrink-76 {
  -webkit-flex-shrink: 76;
  -ms-flex-negative: 76;
  flex-shrink: 76;
}

.react-flex-v2--flex-shrink-77 {
  -webkit-flex-shrink: 77;
  -ms-flex-negative: 77;
  flex-shrink: 77;
}

.react-flex-v2--flex-shrink-78 {
  -webkit-flex-shrink: 78;
  -ms-flex-negative: 78;
  flex-shrink: 78;
}

.react-flex-v2--flex-shrink-79 {
  -webkit-flex-shrink: 79;
  -ms-flex-negative: 79;
  flex-shrink: 79;
}

.react-flex-v2--flex-shrink-80 {
  -webkit-flex-shrink: 80;
  -ms-flex-negative: 80;
  flex-shrink: 80;
}

.react-flex-v2--flex-shrink-81 {
  -webkit-flex-shrink: 81;
  -ms-flex-negative: 81;
  flex-shrink: 81;
}

.react-flex-v2--flex-shrink-82 {
  -webkit-flex-shrink: 82;
  -ms-flex-negative: 82;
  flex-shrink: 82;
}

.react-flex-v2--flex-shrink-83 {
  -webkit-flex-shrink: 83;
  -ms-flex-negative: 83;
  flex-shrink: 83;
}

.react-flex-v2--flex-shrink-84 {
  -webkit-flex-shrink: 84;
  -ms-flex-negative: 84;
  flex-shrink: 84;
}

.react-flex-v2--flex-shrink-85 {
  -webkit-flex-shrink: 85;
  -ms-flex-negative: 85;
  flex-shrink: 85;
}

.react-flex-v2--flex-shrink-86 {
  -webkit-flex-shrink: 86;
  -ms-flex-negative: 86;
  flex-shrink: 86;
}

.react-flex-v2--flex-shrink-87 {
  -webkit-flex-shrink: 87;
  -ms-flex-negative: 87;
  flex-shrink: 87;
}

.react-flex-v2--flex-shrink-88 {
  -webkit-flex-shrink: 88;
  -ms-flex-negative: 88;
  flex-shrink: 88;
}

.react-flex-v2--flex-shrink-89 {
  -webkit-flex-shrink: 89;
  -ms-flex-negative: 89;
  flex-shrink: 89;
}

.react-flex-v2--flex-shrink-90 {
  -webkit-flex-shrink: 90;
  -ms-flex-negative: 90;
  flex-shrink: 90;
}

.react-flex-v2--flex-shrink-91 {
  -webkit-flex-shrink: 91;
  -ms-flex-negative: 91;
  flex-shrink: 91;
}

.react-flex-v2--flex-shrink-92 {
  -webkit-flex-shrink: 92;
  -ms-flex-negative: 92;
  flex-shrink: 92;
}

.react-flex-v2--flex-shrink-93 {
  -webkit-flex-shrink: 93;
  -ms-flex-negative: 93;
  flex-shrink: 93;
}

.react-flex-v2--flex-shrink-94 {
  -webkit-flex-shrink: 94;
  -ms-flex-negative: 94;
  flex-shrink: 94;
}

.react-flex-v2--flex-shrink-95 {
  -webkit-flex-shrink: 95;
  -ms-flex-negative: 95;
  flex-shrink: 95;
}

.react-flex-v2--flex-shrink-96 {
  -webkit-flex-shrink: 96;
  -ms-flex-negative: 96;
  flex-shrink: 96;
}

.react-flex-v2--flex-shrink-97 {
  -webkit-flex-shrink: 97;
  -ms-flex-negative: 97;
  flex-shrink: 97;
}

.react-flex-v2--flex-shrink-98 {
  -webkit-flex-shrink: 98;
  -ms-flex-negative: 98;
  flex-shrink: 98;
}

.react-flex-v2--flex-shrink-99 {
  -webkit-flex-shrink: 99;
  -ms-flex-negative: 99;
  flex-shrink: 99;
}

.react-flex-v2--flex-shrink-100 {
  -webkit-flex-shrink: 100;
  -ms-flex-negative: 100;
  flex-shrink: 100;
}

.react-date-field--theme-default {
  border: 1px solid gray;
  &.react-date-field--focused {
	border: 1px solid #349aef;
  }
  > .react-date-field__picker {
	box-shadow: 0 2px 12px rgba(0, 0, 0, 0.34375);
	border: 1px solid #349aef;
  }
  .react-date-field__calendar-icon {
	border: 2px solid gray;
	&:before, &:after {
	  width: 2px;
	  height: 5px;
	  top: -5px;
	}
	&:before {
	  left: 2px;
	}
	&:after {
	  right: 1px;
	  left: auto;
	}
  }
  .react-date-field__clear-icon {
	color: gray;
	fill: gray;
	&:hover {
	  color: #4d4d4d;
	  fill: #4d4d4d;
	}
  }
  &.react-date-field--focused .react-date-field__clear-icon {
	color: #349aef;
	fill: #349aef;
  }
  &:not(.react-date-field--disabled) .react-date-field__calendar-icon:hover {
	border-color: #4d4d4d;
	cursor: pointer;
	&:after, &:before, .react-date-field__calendar-icon-inner {
	  background: #4d4d4d;
	}
  }
  .react-date-field__calendar-icon {
	&:after, &:before {
	  background: gray;
	}
  }
  .react-date-field__calendar-icon-inner {
	background: gray;
  }
  &.react-date-field--focused .react-date-field__calendar-icon {
	border-color: #349aef;
	&:hover, &:active {
	  border-color: #349aef;
	}
  }
  &:not(.react-date-field--disabled) .react-date-field__calendar-icon:active {
	border-color: #349aef;
  }
  &.react-date-field--focused .react-date-field__calendar-icon {
	&:after, &:before, .react-date-field__calendar-icon-inner {
	  background: #349aef;
	}
	&:hover {
	  &:after, &:before, .react-date-field__calendar-icon-inner {
		background: #349aef;
	  }
	}
	&:active {
	  &:after, &:before, .react-date-field__calendar-icon-inner {
		background: #349aef;
	  }
	}
  }
  &:not(.react-date-field--disabled) .react-date-field__calendar-icon:active {
	&:after, &:before, .react-date-field__calendar-icon-inner {
	  background: #349aef;
	}
  }
}

.react-date-picker__clock--theme-default {
  .react-date-picker__clock-hand-second {
	background: red;
  }
  .react-date-picker__clock-center {
	background: #e6e6e6;
  }
  .react-date-picker__clock-overlay {
	background: white;
	border-style: solid;
	border-color: gray;
  }
}

.react-date-picker__footer--theme-default {
  padding: 5px;
  .react-date-picker__footer-button {
	padding: 3px 4px;
	outline: none;
	position: relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid gray;
	background: white;
	font-size: 0.9em;
	&:active {
	  top: 1px;
	}
	&:not(.react-date-picker__footer-button--disabled) {
	  cursor: pointer;
	  &:hover {
		background: #349aef;
		border-color: #349aef;
		color: white;
	  }
	}
	+ .react-date-picker__footer-button {
	  margin-left: 3px;
	}
  }
}

.react-date-picker__date-format-spinner--theme-default {
  border: 1px solid gray;
  input {
	padding: 5px;
	border: none;
	outline: none;
  }
  &:not([disabled]) {
	&.react-date-picker__date-format-spinner--focused {
	  border: 1px solid #349aef;
	}
	.react-date-picker__date-format-spinner-arrow {
	  position: relative;
	  cursor: pointer;
	  &:active {
		fill: #349aef;
		top: 1px;
	  }
	}
  }
}

.react-date-picker__year-view--theme-default {
  border: 1px solid gray;
  padding: 2px;
  .react-date-picker__year-view-month {
	padding: 5px;
	cursor: pointer;
	border: 2px solid transparent;
  }
  .react-date-picker__year-view-month--disabled {
	color: #D8D8D8;
  }
  .react-date-picker__year-view-month--active {
	border: 2px solid #349aef;
  }
  .react-date-picker__year-view-month--value {
	color: white;
	background: #349aef padding-box;
	border: 2px solid #349aef;
  }
  .react-date-picker__year-view-month--active.react-date-picker__year-view-month--value {
	background: #4ca6f1 padding-box;
  }
}

.react-date-picker__decade-view--theme-default {
  border: 1px solid gray;
  padding: 2px;
  .react-date-picker__decade-view-arrow {
	cursor: pointer;
	position: relative;
	fill: #676767;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  .react-date-picker__decade-view-arrow--disabled {
	fill: #C5C5C5;
  }
  .react-date-picker__decade-view-arrow:not(.react-date-picker__decade-view-arrow--disabled):active {
	left: 1px;
  }
  .react-date-picker__decade-view-year {
	padding: 5px;
	cursor: pointer;
	border: 2px solid transparent;
  }
  .react-date-picker__decade-view-year--disabled {
	color: #D8D8D8;
  }
  .react-date-picker__decade-view-year--active {
	border: 2px solid #349aef;
  }
  .react-date-picker__decade-view-year--value {
	color: white;
	background: #349aef padding-box;
	border: 2px solid #349aef;
  }
  .react-date-picker__decade-view-year--active.react-date-picker__decade-view-year--value {
	background: #4ca6f1 padding-box;
  }
}

.react-date-picker__history-view--theme-default {
  border: 1px solid gray;
  padding: 2px;
  .react-date-picker__year-view--theme-default, .react-date-picker__decade-view--theme-default {
	border: none;
  }
}

.react-date-picker__nav-bar .react-date-picker__history-view--theme-default {
  font-size: 0.833em;
}

.react-date-picker__nav-bar--theme-default {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 1.2rem;
  .react-date-picker__nav-bar-secondary-arrow {
	margin-right: 7px;
  }
  .react-date-picker__nav-bar-history-view {
	background: white;
  }
  &.react-date-picker__nav-bar--with-history-view .react-date-picker__nav-bar-date {
	cursor: pointer;
  }
  .react-date-picker__nav-bar-arrow {
	position: relative;
	fill: #676767;
  }
  .react-date-picker__nav-bar-arrow--disabled {
	fill: #C5C5C5;
  }
  .react-date-picker__nav-bar-arrow:not(.react-date-picker__nav-bar-arrow--disabled) {
	&:hover {
	  fill: #9a9a9a;
	}
	&:active {
	  top: 1px;
	}
  }
}

.react-date-picker__calendar--theme-default {
  border: 1px solid gray;
  .react-date-picker__month-view--theme-default {
	border: none;
  }
  .react-date-picker__clock {
	margin: 10px;
  }
  font-size: 16px;
  font-size: 1em;
}

.react-date-picker__month-view--theme-default, .react-date-picker__date-field--theme-default {
  font-size: 16px;
  font-size: 1em;
}

.react-date-picker__transition-month-view--theme-default {
  font-size: 16px;
  font-size: 1em;
  border: 1px solid gray;
  .react-date-picker__month-view--theme-default, .react-date-picker__multi-month-view--theme-default, .react-date-picker__calendar--theme-default {
	border: none;
  }
}

.react-date-picker__navigation-view--theme-default {
  border: 1px solid gray;
  .react-date-picker__month-view, .react-date-picker__multi-month-view {
	border: none;
  }
}

.react-date-picker__month-view--theme-default {
  background: white;
  position: relative;
  border: 1px solid gray;
  overflow: hidden;
  .react-date-picker__month-view-week-day-names {
	text-transform: uppercase;
  }
  .react-date-picker__month-view-week-number {
	color: #B1B1B1;
	font-size: 0.8em;
  }
  .react-date-picker__month-view-cell {
	min-width: 40px;
  }
  .react-date-picker__month-view-day {
	z-index: 10;
	.react-date-picker__month-view-day-text {
	  border: 2px solid transparent;
	  border-radius: 50%;
	  cursor: pointer;
	  text-align: center;
	  min-width: 40px;
	  min-height: 40px;
	  max-width: 40px;
	  max-height: 40px;
	  display: -webkit-box;
	  display: -webkit-flex;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-align: center;
	  -webkit-align-items: center;
	  -ms-flex-align: center;
	  align-items: center;
	  -webkit-box-pack: center;
	  -webkit-justify-content: center;
	  -ms-flex-pack: center;
	  justify-content: center;
	  &:hover {
		background: #D8EDFF padding-box;
	  }
	}
  }
  .react-date-picker__month-view-day--in-hover-range, .react-date-picker__month-view-day--in-range {
	overflow: hidden;
  }
  .react-date-picker__month-view-day--in-hover-range .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--in-range .react-date-picker__month-view-day-text {
	position: relative;
  }
  .react-date-picker__month-view-day--in-hover-range .react-date-picker__month-view-day-text {
	&:after, &:before {
	  position: absolute;
	  height: 100%;
	  top: 0px;
	  bottom: 0px;
	  width: 500%;
	  background: #349aef;
	  z-index: -1;
	  content: '';
	}
  }
  .react-date-picker__month-view-day--in-range .react-date-picker__month-view-day-text {
	&:after, &:before {
	  position: absolute;
	  height: 100%;
	  top: 0px;
	  bottom: 0px;
	  width: 500%;
	  background: #349aef;
	  z-index: -1;
	  content: '';
	}
  }
  .react-date-picker__month-view-day--in-hover-range .react-date-picker__month-view-day-text:before, .react-date-picker__month-view-day--in-range .react-date-picker__month-view-day-text:before {
	right: 50%;
  }
  .react-date-picker__month-view-day--in-hover-range .react-date-picker__month-view-day-text:after, .react-date-picker__month-view-day--in-range .react-date-picker__month-view-day-text:after {
	left: 50%;
  }
  .react-date-picker__month-view-day--in-hover-range {
	&.react-date-picker__month-view-day--hover-range-start .react-date-picker__month-view-day-text:before, &.react-date-picker__month-view-day--range-start:not(.react-date-picker__month-view-day--in-hover-range) .react-date-picker__month-view-day-text:before {
	  display: none;
	}
  }
  .react-date-picker__month-view-day--in-range {
	&.react-date-picker__month-view-day--hover-range-start .react-date-picker__month-view-day-text:before, &.react-date-picker__month-view-day--range-start:not(.react-date-picker__month-view-day--in-hover-range) .react-date-picker__month-view-day-text:before {
	  display: none;
	}
  }
  .react-date-picker__month-view-day--in-hover-range {
	&.react-date-picker__month-view-day--hover-range-end .react-date-picker__month-view-day-text:after, &.react-date-picker__month-view-day--range-end:not(.react-date-picker__month-view-day--in-hover-range) .react-date-picker__month-view-day-text:after {
	  display: none;
	}
  }
  .react-date-picker__month-view-day--in-range {
	&.react-date-picker__month-view-day--hover-range-end .react-date-picker__month-view-day-text:after, &.react-date-picker__month-view-day--range-end:not(.react-date-picker__month-view-day--in-hover-range) .react-date-picker__month-view-day-text:after {
	  display: none;
	}
  }
  .react-date-picker__month-view-day--in-hover-range.react-date-picker__month-view-day--hover-range-start:not(.react-date-picker__month-view-day--hover-range-end) .react-date-picker__month-view-day-text:after, .react-date-picker__month-view-day--in-range.react-date-picker__month-view-day--hover-range-start:not(.react-date-picker__month-view-day--hover-range-end) .react-date-picker__month-view-day-text:after, .react-date-picker__month-view-day--in-hover-range.react-date-picker__month-view-day--hover-range-end:not(.react-date-picker__month-view-day--hover-range-start) .react-date-picker__month-view-day-text:before, .react-date-picker__month-view-day--in-range.react-date-picker__month-view-day--hover-range-end:not(.react-date-picker__month-view-day--hover-range-start) .react-date-picker__month-view-day-text:before {
	display: inherit;
  }
  .react-date-picker__month-view-day--in-hover-range.react-date-picker__month-view-day--active:not(.react-date-picker__month-view-day--range-start):not(.react-date-picker__month-view-day--range-end):not(.react-date-picker__month-view-day--hover-range-start):not(.react-date-picker__month-view-day--hover-range-end) .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--in-range.react-date-picker__month-view-day--active:not(.react-date-picker__month-view-day--range-start):not(.react-date-picker__month-view-day--range-end):not(.react-date-picker__month-view-day--hover-range-start):not(.react-date-picker__month-view-day--hover-range-end) .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--in-hover-range.react-date-picker__month-view-day--active:not(.react-date-picker__month-view-day--range-start):not(.react-date-picker__month-view-day--range-end):not(.react-date-picker__month-view-day--hover-range-start):not(.react-date-picker__month-view-day--hover-range-end) .react-date-picker__month-view-day-text:hover, .react-date-picker__month-view-day--in-range.react-date-picker__month-view-day--active:not(.react-date-picker__month-view-day--range-start):not(.react-date-picker__month-view-day--range-end):not(.react-date-picker__month-view-day--hover-range-start):not(.react-date-picker__month-view-day--hover-range-end) .react-date-picker__month-view-day-text:hover {
	background: #93c9f6 padding-box;
  }
  .react-date-picker__month-view-day--in-hover-range .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--in-range .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--value .react-date-picker__month-view-day-text {
	border: 2px solid transparent;
	background: #349aef padding-box;
	color: white;
  }
  .react-date-picker__month-view-day--in-hover-range .react-date-picker__month-view-day-text:hover, .react-date-picker__month-view-day--in-range .react-date-picker__month-view-day-text:hover, .react-date-picker__month-view-day--value .react-date-picker__month-view-day-text:hover {
	background: #349aef padding-box;
  }
  .react-date-picker__month-view-day--in-hover-range.react-date-picker__month-view-day--today-highlight .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--in-range.react-date-picker__month-view-day--today-highlight .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--value.react-date-picker__month-view-day--today-highlight .react-date-picker__month-view-day-text {
	color: #ffccff;
  }
  .react-date-picker__month-view-day--in-hover-range .react-date-picker__month-view-day-text {
	background: #d9ecfc padding-box;
	&:after, &:before {
	  background: #d9ecfc;
	}
  }
  .react-date-picker__month-view-day--hover-range-start .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--hover-range-end .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--range-start .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--range-end .react-date-picker__month-view-day-text, .react-date-picker__month-view-day--hover-range-start .react-date-picker__month-view-day-text:hover, .react-date-picker__month-view-day--hover-range-end .react-date-picker__month-view-day-text:hover, .react-date-picker__month-view-day--range-start .react-date-picker__month-view-day-text:hover, .react-date-picker__month-view-day--range-end .react-date-picker__month-view-day-text:hover {
	background: #63b2f3 padding-box;
  }
  .react-date-picker__month-view-day--active .react-date-picker__month-view-day-text {
	border: 2px solid #349aef;
  }
  .react-date-picker__month-view-day--prev-month, .react-date-picker__month-view-day--next-month {
	color: #b3b3b3;
  }
  .react-date-picker__month-view-day--weekend-highlight {
	color: red;
	&.react-date-picker__month-view-day--prev-month, &.react-date-picker__month-view-day--next-month {
	  color: #d68e8e;
	}
  }
  .react-date-picker__month-view-day--today-highlight {
	color: magenta;
	&.react-date-picker__month-view-day--prev-month, &.react-date-picker__month-view-day--next-month {
	  color: #ff66ff;
	}
  }
  .react-date-picker__month-view-day--disabled {
	color: #D8D8D8;
	.react-date-picker__month-view-day-text {
	  cursor: default;
	  &:hover {
		background: none;
	  }
	}
	&.react-date-picker__month-view-day--prev-month, &.react-date-picker__month-view-day--next-month {
	  color: #D8D8D8;
	}
  }
}

.react-date-picker__multi-month-view {
  border: 1px solid gray;
  .react-date-picker__month-view {
	border: none;
  }
}