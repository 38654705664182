.blocking-modal {
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: rgba($headerTopBackground, 0.8);
  top: 0;

  .body {
    margin: 10% auto;
    width: 550px;
    .ap-remove{
      color: #fff;
      cursor: pointer;
      opacity: 0.5;
      margin-right: -20px;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    .body {
      width: 100%;
      margin: -1px;
    }
  }
}

// set up react-bootstrap modal

@keyframes example {
  0%   {top:400px;}
  100% {top:0px;}
}

.modal-dialog .modal-content {
  border-radius: 3px;
  box-shadow: none;
  border: none;
  @media (max-width: $mobileBreakpoint) {
    border-radius: 0px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .modal-dialog {
    margin: 0px;

  }
  .modal-backdrop.in {
    display: none;
  }
  div[role="dialog"] {
    background-color: #fff;
    padding: 0;
  }

  .fade{
    /* Safari 4.0 - 8.0 */
    -webkit-animation-name: example;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-timing-function: ease;
    -webkit-animation-direction: alternate;
    /* Standard syntax */
    animation-name: example;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-direction: alternate;
  }
}



@media (min-width: $mobileBreakpoint) {
  .fade.in.modal {
      & > * {
        margin: 30px auto;
      }
  }
}
