.expand-button {
    padding: 0;
    border-radius: 3px;

    .content {
        padding: 25px;
        background-color: #fff;
        color: $primaryTextColor;
    }
    .header {
        padding: 17px;
        text-align: center;
    }

    .icon {
        float: left;
        margin: -9px;
        font-size: 35px;
        line-height: 35px;
        border-radius: 3px;
        text-decoration: none;
        cursor: pointer;
    }

    &:not(.expanded) {
        @extend .btn;
        @extend .btn-lg;
        @extend .btn-block;
        margin-bottom: 30px;

        &.default {
            @extend .btn-default;
            border: 1px solid $cardBorderColor;
        }
    }

    &.expanded {
        margin-bottom: 30px;

        &.default {
            border: 3px solid $dialogColor;
            background-color: $dialogColor;
            color: #fff;
            .icon {
                background-color: #fff;
                color: $dialogColor;
            }
        }
        &.success {
            border: 3px solid $successColor;
            background-color: $successColor;
            color: #fff;
        }
        &.warning {
            border: 3px solid $warningColor;
            background-color: $warningColor;
            color: #fff;
        }
        &.danger {
            border: 3px solid $dangerColor;
            background-color: $dangerColor;
            color: #fff;
        }
    }

    &.success {
        @extend .btn-action;
        .icon {
            background-color: #fff;
            color: $successColor;
        }
    }
    &.danger {
        @extend .btn-danger;
        .icon {
            background-color: #fff;
            color: $dangerColor;
        }

        // Bootstrap overwrites
        &:active:focus {
            background: $dangerColor!important;
            border-color: $dangerColor!important;
        }

        &:active:hover {
            background: $dangerColor!important;
            border-color: $dangerColor!important;
        }
    }
    &.warning {
        @extend .btn-warning;
        .icon {
            background-color: #fff;
            color: $warningColor;
        }

        // Bootstrap overwrites
        &:active:focus {
            background: $warningColor!important;
            border-color: $warningColor!important;
        }

        &:active:hover {
            background: $warningColor!important;
            border-color: $warningColor!important;
        }
    }

    .highlighted-item {
        margin: 0 0 25px 0;
        padding-left: 20px;
        border-left: 7px solid #E1E7E9;
        border-radius: 2px;
    }

    hr {
        margin: 25px 0;
    }
}