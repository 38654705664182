#leads-page {

    .lead-bought {

        .description {
            max-height: 260px;
            overflow: hidden;
            position: relative;

            &:after {
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.67+38,1+96 */
                background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.67) 38%, rgba(255,255,255,1) 96%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.67) 38%,rgba(255,255,255,1) 96%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.67) 38%,rgba(255,255,255,1) 96%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 160px;
                margin-bottom: -1px;
            }
        }

        .datafields {
            display: none;
        }
    }
}
