.prompt {
    &.ad {
        border: 1px solid #00A4EB;
    }

    .dismiss {
        float: right;
        cursor: pointer;
        text-decoration: none;
        font-weight: bold;
        font-size: 20px;
        margin-top: -20px;
        margin-right: -10px;
        color: $promptDismissColor;
    }
}
