// Desktop and tablet

.icon {
    display: block;

    line-height: 40px;
    text-align: center;
    width: 40px;
    height: 40px;
    font-size: 16px;

    background-color: $activeColor;
    color: #fff;

    &.icon-round {
        border-radius: 100px;
        line-height: 48px;
        width: 45px;
        height: 45px;
    }

    &.icon-left {
        float: left;
        margin-right: 20px;
    }
}

.large-text-icon {
    font-size: 50px;
    font-family: helvetica;
    color: $textIconColor;
}

.lead-bought-btn .icon {
    background-color: white;
    color: $activeColor;
    font-size: 18px;
    line-height: 47px;
}

// Mobile (smaller icon)

@media (max-width: $userDropdownBreakpoint) {
    .icon {
        font-size: 12px;

        &.icon-round {
            width: 35px;
            height: 35px;
            line-height: 40px;
        }
    }
}

// Icon font sizes

.small-icon {
    font-size: 14px;
}

.medium-icon {
    font-size: 20px;
    width: 45px;
}

.large-icon {
    font-size: 24px;
}

.badge-indicator {
    position: relative;
    &:after {
        background-color: #fa3e3e;
        border-radius: 50%;
        color: white;
        padding: 5px 5px;
        font-size: 10px;
        position: absolute;
        content: ' ';
        top: -1px;
        right: -3px;
    }
}

#desktop-menu {
    .badge-indicator:after  {
        top: 0px;
        right: -10px;
    }
}
