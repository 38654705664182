// Libraries
@import "libraries/bootstrap/bootstrap";
@import "libraries/date_picker";
@import "libraries/datetime";
@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "~@mdi/font/scss/materialdesignicons";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import "~react-bootstrap-typeahead/css/Typeahead.css";

// Base
@import "base/variables";
@import "base/mixins";
@import "base/general";
@import "base/typography";
@import "base/navigation";
@import "base/forms";
@import "base/cards";
@import "base/prompts";
@import "base/buttons";
@import "base/lists";
@import "base/loading";
@import "base/icons";
@import "base/alerts";
@import "base/payment";
@import "base/tables";
@import "base/labels";
@import "base/transitions";
@import "base/modals";
@import "base/inputs";
@import "base/animation";

// Icon font
@import "punch-font/style.css";

// Components
@import "components/card";
@import "components/lead_item";
@import "components/pageHeader";
@import "components/pageFooter";
@import "components/Sidebar";
@import "components/RatingItem";
@import "components/ActionCheckBox";
@import "components/OfferBar";
@import "components/ScrollAlongBar";
@import "components/StepBox";
@import "components/RatingStarBox";
@import "components/LeadBuyButton";
@import "components/ExpandButton";
@import "components/LoadingBar";
@import "components/ReactSelect";
@import "components/informationBar";
@import "components/Portrait";
@import "components/ProjectFinder";
@import "components/ProjectMessage";
@import "components/CardCheckBox";
@import "components/MessageAttachment";
@import "components/NotificationDropdown";
@import "components/PunchesDropdown";
@import "components/SatisfactionPrompt";
@import "components/DeliverableStatusBadge";
@import "components/SlickCarusel";

// Pages
@import "pages/home";
@import "pages/addUser";
@import "pages/demo";
@import "pages/proUpgrade";
@import "pages/certifications";
@import "pages/login";
@import "pages/cv";
@import "pages/leadDetail";
@import "pages/miniLanding";
@import "pages/leads";
@import "pages/quotePage";
@import "pages/checkout";
@import "pages/subscription";
@import "pages/onboarding";
@import "pages/payment";
@import "pages/clientMessagePage";
@import "pages/signup";

// Mobile overrides
@import "base/mobile";
