ul.terms-list {
    margin: 15px 0;
    padding: 0;

    li {
        list-style-type: none;
        margin: 0;

        label {
            font-weight: 500;
            font-size: 12px;
            text-transform: uppercase;
            color: $introTextColor;
        }

        input {
            margin-right: 9px;
        }

        a {
            text-decoration: underline;
        }
    }
}