#lead-detail-page {

    .heading {
        .icon {
            float: left;
            margin-right: 10px;
            border-radius: 100%;
        }

        h3 {
            overflow: auto;
            line-height: 40px;
            margin: 0;
        }
    }

    .lead-card {
        padding: 0;
        overflow: hidden;
    }

    .pad {
        padding: 40px;
    }

    .sidebar {
        border-left: 1px solid $cardBorderColor;
        background: #fff;
        order: 2;

        .section {
            border-color: $cardBorderColor;
            border-bottom: 1px solid $cardBorderColor;
            padding: 40px;
            margin-left: -15px;

            @media(min-width: 768px) {
                &:last-child {
                    border: none;
                }
            }
        }

        .caption {
            margin-top: 12px;
        }
    }

    .lead-bought-btn {
        display: none;
    }

    ul.lead-info {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    @media(min-width: 768px) {
        .page-row {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
        }
    }

}