$cardPadding: 40px;

.card {
    background-color: #fff;
    border: 1px solid $cardBorderColor;
    border-radius: 3px;
    box-shadow: 0px 2px 4px 0px rgba(200,207,211,0.5);

    margin-top: $cardMarginTop;
    margin-bottom: $cardMarginBottom;
    padding: $cardPadding;

    &.no-padding {
        padding: 0;
    }

    a {
        text-decoration: underline;
    }

    hr {
        margin: 40px -40px;
        border-top: 1px solid $lightGrey;
    }

    .quote {
        border-left: 6px solid #c7d7dd;
        padding: 5px 0 5px 30px;
    }

    ul.meta-info {
        color: $fadedTextColor;
        padding: 0;
        margin: 15px 0 0 0;

        a {
            color: $fadedTextColor;
            text-decoration: underline;
        }
        li {
            list-style-type: none;
            display: inline;
            &:not(:first-child):before {
                content: "·";
                margin: 0 5px;
            }
            &.important {
                color: $activeColor;
            }
        }
    }

    &.has-header {
        padding: 0;

        .header {
            font-size: 20px;
            font-weight: 500;
            border-bottom: 1px solid $sidebarBorderColor;
            //background-color: $cardHeaderBackgroundColor;
            padding: $cardPadding;

            &.topborder {
                border-top: 1px solid $sidebarBorderColor;
            }
        }
        .content {
            padding: $cardPadding;
        }
    }

    @media (max-width: $mobileBreakpoint) {
        .invisible-on-phone {
            .content, .highlighted-container {
                border: 0;
                padding: 0;
            }
        }
    }
}

.dialog {
    border: 4px solid $dialogColor;
    border-radius: 2px;

    .header {
        a {
            float: left;
            color: $dialogColor;
            display: block;
            width: 40px;
            height: 40px;
            background-color: #fff;
            padding: 0px;
            margin: -10px;
            border-radius: 3px;
            font-size: 33px;
            cursor: pointer;
            line-height: 35px;
        }
        padding: 17px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        background-color: $dialogColor;
    }
    .content {
        padding: 20px;
        background-color: #fff;
    }
}
