.form-group {
    label {
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        color: $introTextColor;
    }

    .form-control {
        font-size: 16px;
        padding-right: 15px;
        min-height: 48px;
        box-shadow: none;
        color: $primaryTextColor;
    }
}

textarea {
    border: 1px solid $formInputBorderColor;
    border-radius: 3px;
    padding: 5px;
    width: 100%;
}

input[type="text"], input[type="email"], input[type="password"] {
    &:active, &:focus {
        outline: none;
    }
}

.well input[type='checkbox']{
    margin-right: 10px;
}

.rw-selectlist {
    ul {
        padding: 0;
        margin: 0;
    }
    li {
        list-style-type: none;
        display: inline-block;
        width: 50%;
    }
    label {
        font-weight: normal;
    }
}

.label-list {
    label, p {
        font-weight: normal;
        display: block;
    }
}

.expand-button {
    // CV
    .form-group {
        margin-bottom: 25px;
    }

    .Select-placeholder {
        line-height: 45px;
    }
}

// Placeholders for the input forms across all browsers

input, textarea {

    &::-webkit-input-placeholder { /* Chrome, Safari, Opera */
        color: #909090!important;
        font-weight: 400;
    }

    &::-moz-placeholder {  /* Firefox */
        color: #909090;
        font-weight: 400;
    }

    &:-ms-input-placeholder { /* IE10–11 */
        color: #909090 !important;
        font-weight: 400 !important;
    }

    &::-ms-input-placeholder { /* Edge */
        color: #909090;
        font-weight: 400;
    }

    &::placeholder { /* CSS Working Draft */
        color: #909090!important;
        font-weight: 400;
    }

}

.form-compact {
    .form-control {
        padding: 2px 4px;
        font-size: 14px;
        height: 28px;
    }
}

.input-group.currency {
    input {
        text-align: end;
        border-right-width: 0;
        height: 32px;
        padding-right: 0;
        &:focus {
            clip-path: inset(-5px 0px -5px -5px)
        }
        &:focus + span {
            border-color: #66afe9;
            outline: 0;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
            clip-path: inset(-5px -5px -5px 0px);
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        }
    }
    .input-group-addon {
        background-color: inherit;
    }
}

$switchBaseColor: lighten($successColor, 0%);
$switchBorderColor: $switchBaseColor;
$switchBorderBottomColor: darken($switchBaseColor, 5%);
$switchBorderTopColor: darken($switchBaseColor, 5%);
$switchBackgroundGradientStartColor: lighten($switchBaseColor, 15%);
$switchBackgroundGradientEndColor: lighten($switchBaseColor, 5%);

$switchKnobColor: #fff;

.switch {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    font-weight: normal;
    margin: 7px 0;

    &:hover &.knob {
        background: #fff
    }

    .source {
        position: absolute;
        opacity: 0;
        pointer-events: none;

        &:checked+.bevel {
            border: 1px solid $switchBorderColor;
            border-bottom-color: $switchBorderTopColor;
            border-top-color: $switchBorderTopColor;
            background: linear-gradient(180deg, $switchBackgroundGradientStartColor, $switchBackgroundGradientEndColor)
        }

        &:checked+.bevel .knob {
            left: 21px
        }

        &:disabled+.bevel {
            opacity: .5;
            pointer-events: none
        }
    }

    .knob {
        left: 2px;
        position: absolute;
        top: 2px;
        height: 22px;
        width: 22px;
        background: $switchKnobColor;
        border-radius: 100%;
        box-shadow: 0 0 0 1px rgba(86, 109, 121, .2), 0 2px 3px rgba(0, 0, 0, .22);
        transition: left .1s ease;
    }

    .bevel {
        position: relative;
        display: inline-block;
        height: 28px;
        width: 47px;
        background: linear-gradient(180deg, #e9ecef, #f0f3f5);
        border: 1px solid #ced5db;
        border-bottom-color: #d3d9de;
        border-top-color: #c9d1d7;
        border-radius: 28px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, .07);
        cursor: pointer;
        transition: background-color .1s ease;
        margin-right: 10px;
    }
}
