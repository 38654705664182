.nav.nav-tabs {
    margin-top: 0;
}
.nav.nav-pills {
    margin-top: 0;
    margin-bottom: 30px;

    li {
        a {
            border-radius: 100px;
            background-color: #fff;
            color: $darkBlue;
            padding: 20px 30px;
            margin-right:20px;
            box-shadow: 0px 2px 4px 0px rgba(200,207,211,0.5);
            text-decoration: none;

            &.active {
                background-color: $activeColor;
                color: #fff;
            }
        }
    }
}


.breadcrumb {
    margin: 0;
    padding: 20px 10px;
    border: 1px solid $cardBorderColor;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    display: -webkit-flex;
    background-color: #fff;
    color: $fadedTextColor;
    div, span {
        margin: 0 9px;
        display: -webkit-inline-flex;
    }

    a {
        text-decoration: none !important;
        display: flex;
        .step-text {
            line-height: 20px;
        }
    }

    .step {
        &.active {
            a {
                color: $activeColor;
                font-weight: 500;
            }
            .checkbox.soft-false {
                border-color: $activeColor;
            }
        }
        &.passed a {
            color: $fadedTextColorTwo;
        }
        .action-checkbox {
            .checkbox.soft-false, .ap-checkmark {
                margin: 0;
                width: 20px;
                height: 20px;
                border-width: 4px;
            }
            .ap-checkmark {
                padding: 6px;
                &::before {
                    font-size: 8px;
                }
            }
        }
    }

    .ap-arrow-right {
        font-size: 8px;
        margin: -3px;
        color: $dialogColor;
    }
}

.step-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
}
