.label {
    padding: 0.4em .6em .4em;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;

    &.pro {
        background-color: $activeColor;
    }

    &.new {
        background-color: $activeColor;
        font-size: 9px;
        vertical-align: middle;
    }

    &.quote-accepted {
        background-color: $successColor;
    }
    &.quote-new {
        background-color: $activeColor;
    }
    &.quote-rejected {
        background-color: $dangerColor;
    }
}
