$dataTableBreakpoint: 700px;
$dataMobileBreakpoint: 550px;

$headerBackground: #F8FAFC;
$cellBorderColor: #E6EBF0;
$tableBorderColor: #C7D6DC;
$columnHeaderTextColor: #6F8289;
$highlightBackgroundColor: $headerBackground;
$rowHeight: 60px;
$cellPadding: 30px;

table.datatable {
    width: 100%;

    th {
        font-weight: 500;
        background-color: $headerBackground;
        color: $columnHeaderTextColor;
        text-transform: uppercase;
        font-size: 12px;
        height: $rowHeight;
    }

    th, td {
        vertical-align: middle;
        border: 1px solid $cellBorderColor;
        height: $rowHeight;
        padding: 0px $cellPadding;
    }

    tr {
        &.clickable {
            cursor: pointer;
            &:hover {
                background-color: $highlightBackgroundColor;
            }
        }
        &.sum {
            td {
                border-top-width: 2px;
            }
        }
        &.highlight {
            td {
                background-color: #f9f9f9;
            }
        }
    }

    td {
        border-top: 1px solid $sidebarBorderColor;

        &.amount {
            text-align: right;
        }

        &.success {
            color: $successColor;
            font-weight: bold;
        }

        &.danger {
            color: $dangerColor;
            font-weight: bold;
        }
    }

    &.compact {
        th, td {
            padding: 0px $cellPadding*.5;
            height: $rowHeight - 8px;
        }
        td {
            font-size: $baseFontSize;
        }
    }

    &.responsive {

        @media (max-width: $dataTableBreakpoint - 1px) {

            > thead > tr > th {
                display: none;
            }

            > tbody > tr, a > td {
                display: none;
            }

            .table-mobile-cell, .table-responsive-cell, .table-responsive-row td {
                display: table-cell;
            }
        }

        &_mobile {
            .table-mobile-cell{
                display: none
            }
            @media (max-width: $mobileBreakpoint) {

                > thead > tr > th {
                    display: none;
                }

                > tbody > tr, a > td {
                    display: none;
                }

                .table-mobile-cell, .table-responsive-cell, .table-responsive-row td {
                    display: table-cell;
                }
            }
        }

        @media (min-width: $dataTableBreakpoint) {
            .table-mobile-cell {
                display: none;
            }

            .table-responsive-cell, .table-responsive-row td {
                display: table-cell;
            }
        }

    }

    &.table-form {
        input {
            font-size: 16px;
        }
        thead tr {
            height: 45px;
        }
        th, td {
            height: initial;
            padding: 7px;
            input[type="checkbox"] {
                width: 15px;
                height: 15px;
                cursor: pointer;
                margin: auto;
                display: block;
            }
            .help-block {
                margin-bottom: 0;
            }
        }
    }
}

table.definition-list {
    border: 0;
    width: 100%;

    td {
        background-color: transparent !important;
        border: 0;
        padding: 0;
        padding-bottom: 5px;

        &:first-child {
            font-weight: 500;
            padding-right: 10px;
        }

        &:last-child {
            text-align: right;
        }
    }
}

// Comparison list (table) for quote overview page in Client Portal

.comparison-list {
    margin-top: 60px;

    @media (max-width: 740px) {
        display: none;
    }

    &_heading {
        margin: 40px 0 20px 0;
        font-size: 26px;

        @media (max-width: $dataMobileBreakpoint) {
            font-size: 18px;
        }
    }

    &_row {
        background: rgba(248, 250, 251,.6);

        &:nth-child(odd) {
            background: rgba(255,255,255,.6);
        }

        &_inner {
            display: flex;
            padding: 15px 0;
        }

        &[type='company'] {
            background: $backgroundColor;
        }
    }

    &_column {
        display: flex;
        flex-basis: 20%;

        &:first-child {
            flex-basis: 40%;
            font-weight: 500;


            @media (min-width: $dataMobileBreakpoint) {
                padding-right: 100px;
            }
        }

        @media (max-width: $dataMobileBreakpoint) {
            font-size: 12px;
        }

        &_total {
            font-weight: 500;
        }
    }

    .heading {

        &_name {
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: .3px;
            height: 40px;
            overflow: hidden;

            @media (max-width: $dataMobileBreakpoint) {
                font-size: 12px;
                display: none;
            }
        }

        &_price {
            color: $primaryColor;
            font-size: 20px;
            margin: 5px 0 20px 0;

            @media (max-width: $dataMobileBreakpoint) {
                font-size: 12px;
            }
        }

        &_button {

            @media (max-width: $dataMobileBreakpoint) {
                padding: 6px;
                font-size: 12px;
                max-width: 60px;
                overflow: hidden;
                text-align: ellipsis;
            }
        }
    }

    .quote-type {
        font-weight: normal;
    }

    .rating-number {
        @media (min-width: $dataMobileBreakpoint) {
            display: none;
        }
    }

    .rating-stars {
        @media (max-width: $dataMobileBreakpoint) {
            display: none;
        }
    }
}

.table-review-rating {
    width: 100%;
    tr {
        &:nth-child(odd) {
            background-color: #f9f9f9;
        }
        border-top: 1px solid #ddd;
        td {
            vertical-align: middle;
            padding: 15px 10px;
            &:last-child {
                text-align: end;
            }
        }
    }
}
