// Single SASS file for animation
$square-ani1: jump 2.5s infinite;
$square-ani2: jump 2.5s .3s infinite;
$square-ani3: jump 2.5s .6s infinite;
$square-ani4: jump 2.5s .9s infinite;

.whitefill {
    background-color: #fff;
    position: absolute;
    height: 100%;
    width: 100%;
}

.loading-overlay {
    position: absolute;
    background-color: rgba(255,255,255,0.7);
    width: 100%;
    height: 100%;
    padding: 30px;
    z-index: 2;

    &.empty-list {
        background-color: #fff;
        min-height: 120px;
        position: relative;
    }
}

.logo-loader {
    // position:
    position: relative;
    top: 50%;
    margin: 0 auto;
    // size:
    width: 66px;
    transform: scale(0.9) translateY(-50%);


    .square-generic {
        // position:
        position: absolute;
        // look:
        opacity: 0;
        background: #00a5e4; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(#00adef, #027bab); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(#00adef, #027bab); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(#00adef, #027bab); /* For Firefox 3.6 to 15 */
        background: linear-gradient(#00adef, #027bab); /* Standard syntax */
    }
    .square-1 {
        @extend .square-generic;
        // size:
        width: 39px;
        height: 52px;
        // position:
        left: 21px;
        bottom: 0;
        // animation:
        -webkit-animation: $square-ani1; /* Safari 4+ */
        -moz-animation: $square-ani1; /* Fx 5+ */
        -o-animation: $square-ani1;/* Opera 12+ */
        animation: $square-ani1; /* IE 10+, Fx 29+ */

    }
    .square-2 {
        @extend .square-generic;
        // size:
        width: 29px;
        height: 39px;
        // position:
        left: 0;
        bottom: 39px;
        // animation:
        -webkit-animation: $square-ani2; /* Safari 4+ */
        -moz-animation: $square-ani2; /* Fx 5+ */
        -o-animation: $square-ani2;/* Opera 12+ */
        animation: $square-ani2; /* IE 10+, Fx 29+ */
    }
    .square-3 {
        @extend .square-generic;
        // size:
        width: 19px;
        height: 26px;
        // position:
        left: 46px;
        bottom: 61px;
        // animation:
        -webkit-animation: $square-ani3; /* Safari 4+ */
        -moz-animation: $square-ani3; /* Fx 5+ */
        -o-animation: $square-ani3;/* Opera 12+ */
        animation: $square-ani3; /* IE 10+, Fx 29+ */
    }
    .square-4 {
        @extend .square-generic;
        // size:
        width: 11px;
        height: 15px;
        // position:
        left: 27px;
        bottom: 92px;
        // animation:
        -webkit-animation: $square-ani4; /* Safari 4+ */
        -moz-animation: $square-ani4; /* Fx 5+ */
        -o-animation: $square-ani4;/* Opera 12+ */
        animation: $square-ani4; /* IE 10+, Fx 29+ */
    }
    // Animation of load items
    @keyframes jump {
        0% {
            -webkit-transform: translateY(0px); opacity: 0;
            -moz-transform: translateY(0px); opacity: 0;
            -ms-transform: translateY(0px); opacity: 0;
            -o-transform: translateY(0px); opacity: 0;
            transform: translateY(0px); opacity: 0;
        }
        50% {
            -webkit-transform: translateY(10px); opacity: 1;
            -moz-transform: translateY(10px); opacity: 1;
            -ms-transform: translateY(10px); opacity: 1;
            -o-transform: translateY(10px); opacity: 1;
            transform: translateY(10px); opacity: 1;
        }
        100% {
            -webkit-transform: translateY(20px); opacity: 0;
            -moz-transform: translateY(20px); opacity: 0;
            -ms-transform: translateY(20px); opacity: 0;
            -o-transform: translateY(20px); opacity: 0;
            transform: translateY(20px); opacity: 0;
        }
    }
}

// scaling... any units
$width: 50px;

.loader {
  position: relative;
  margin: 0 auto 0 auto;
  width: $width;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
  stroke: $activeColor;

  &--light {
    stroke: #fff;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
