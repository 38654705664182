.page-splash.onboarding-page {
  h1 {
    font-size: 46px;
    font-weight: 500;
    line-height: 1.17em;
    color: $landingHeaderTextColor;
  }
  h2 {
    font-size: 24px;
    line-height: 1.38em;
    color: $fadedTextColor;
  }
  p {
    line-height: 1.44em;
    color: $landingPageBodyText
  }
}
